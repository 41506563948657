import React, { useState, useEffect, useRef  } from 'react';
import {
  format,
  startOfWeek,
  eachDayOfInterval,
  addWeeks,
  subWeeks,
  getISOWeek,
  addDays,
} from 'date-fns';
import { BiMessageSquareDetail } from 'react-icons/bi';
import SendIcon from '@mui/icons-material/Send';
import {
  Button,
  Typography,
  IconButton,
  Box,
  Modal,
  Grid,
  TextField,
  Container, 
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import PoolIcon from '@mui/icons-material/Pool';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HelpIcon from '@mui/icons-material/Help';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { deleteDietPlan, getUserTrainingSessionsLastTenWeeks, getUserTrainingSessionsLastWeek, createRunPlan, getStrengthQuestionnaireById, getDietPlanQuestionnaireById,
  updateTrainingSessionById,deleteTrainingSessionById, createTrainingSession, getDietPlansbyUserId, getUserById, createErrorLog,getRunQuestionnairesById, createDietPlan, 
  getDailyChatCounter, createChatCounter, updateChatCounter, getDailyTrainingTipCounter, createTrainingTipCounter, updateTrainingTipCounter, createPlanCounter, getMonthlyPlanCounters, updatePlanCounter  } from '../api';
import { TrainingSessions } from '../models/trainingSessions';
import { DietPlan } from '../models/dietPlan';
import { User } from '../models/user';
import { Configuration, OpenAIApi, ChatCompletionRequestMessageRoleEnum  } from 'openai';
import { initializeApp } from 'firebase/app';

type PartialTrainingSessions = {
  [K in keyof TrainingSessions]?: TrainingSessions[K];
};

interface DailyLoad {
  date: Date;
  trainingLoad: number;
  ewma?: number;
  ewmaAcute?: number;
  ewmaChronic?:number;
  ewmaAcwr?: number;
}

const UserDashboard = () => {
  const [chatMessages, setChatMessages] = useState<Array<{ message: string; user: string }>>([]);
  const [trainingSessionsUpdated, setTrainingSessionsUpdated] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [trainingSessions, setTrainingSessions] = useState<TrainingSessions[]>([]);
  const [dietPlans, setDietPlans] = useState<DietPlan[]>([]);
  const [open, setOpen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [dietModalOpen, setDietModalOpen] = useState(false);
  const [chatWindowOpen, setChatWindowOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState<TrainingSessions | null>(null);
  const [selectedDietPlan, setSelectedDietPlan] = useState<DietPlan | null>(null);
  const [updatedSession, setUpdatedSession] = useState<PartialTrainingSessions | null>(null);
  const [newSession, setNewSession] = useState<Partial<TrainingSessions>>({date: new Date()});
  // const [lastWeekTrainingSessions, setLastWeekTrainingSessions] = useState<string>('');
  const [todaysTrainingSessions, setTodaysTrainingSessions] = useState<string>('');
  const [selectedButton, setSelectedButton] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [openAiAPIKey, setOpenAiAPIKey] = useState<string>('');
  const [openAiOrgId, setOpenAiOrgId] = useState<string>('');
  const [trainingLoadData, setTrainingLoadData] = useState<DailyLoad[]>([]);
  const [disableButtonsBasedOnSubStatus, setDisableButtonsBasedOnSubStatus] = useState<boolean>(true);
  const [showButtonsBasedOnCurrQ, setShowButtonsBasedOnCurrQ] = useState<boolean>(false);
  const [disabledIfNotSunday, setDisabledIfNotSunday] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [trainingTip, setTrainingTip] = useState<string>('');
  const [tipsLoading, setTipsLoading] = useState<boolean>(false);
  const [showTip, setShowTip] = useState(false);
  const [openTipDialogue, setOpenTipDialogue] = React.useState(false);
  const [trainingGoal, setTrainingGoal] = React.useState('');
  const [feelingToday, setFeelingToday] = React.useState('');
  const [activityToday, setActivityToday] = React.useState('');
  const weekStart = startOfWeek(selectedWeek, { weekStartsOn: 1 });
  
  // const [selectedValueLabel, setSelectedValueLabel] = useState('');
  // // const [rpe, setRpe] = useState(0);

  // // useEffect(() => {
  // //   if (selectedSession) {
  // //     setRpe(selectedSession.intensityRatingOfPerceivedExertion);
  // //   }
  // // }, [selectedSession]);

  // const labels: Record<number, string> = {
  //   1: 'Very easy',
  //   2: 'Easy',
  //   3: 'Somewhat easy',
  //   4: 'Comfortably hard',
  //   5: 'Comfortably hard+',
  //   6: 'Hard',
  //   7: 'Very hard',
  //   8: 'Extremely hard',
  //   9: 'Almost max',
  //   10: 'Maximal exertion',
  // };
  const isItSunday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    // Change back to 0 when done
    const sundayOrNot = dayOfWeek === 0 || dayOfWeek === 5 || dayOfWeek === 6 ? true : false;
    setDisabledIfNotSunday(sundayOrNot);
  }

  useEffect(() => {
    isItSunday();
  }, []);

  const weekDays = eachDayOfInterval({
    start: weekStart,
    end: addDays(addWeeks(weekStart, 1), -1),
  });
  const theme = useTheme();

  const getsubscriptionStatus = async () => {
    const userId = localStorage.getItem('userId');
    const user: User = await getUserById(userId || '');
    setUser(user);
    if (user.activeSubscription === true) {
      setDisableButtonsBasedOnSubStatus(false);
    } else {
      alert(
        `Your subscription is expired. Please go to the subscription-page and subscribe to use the customized diet and training-plans functions, and to chat with your AI Personal Trainer `);
        setDisableButtonsBasedOnSubStatus(true);
    }
    if(user.currentRunQuestionnaire ||
      user.currentStrengthQuestionnaire || 
      user.currentDietPlanQuestionnaire 
      ) {
        setShowButtonsBasedOnCurrQ(true);
    }
  }

  const firebaseConfig = {
    
  };
  initializeApp(firebaseConfig);

  const fetchOpenAiConfig = async () => {
    try {
      const response = await fetch("https://us-central1-ai-personal-trainer-386016.cloudfunctions.net/getOpenAiConfig");
      const data = await response.json();
      const { openAiAPIKey, openAiOrgId } = data;
      setOpenAiAPIKey(openAiAPIKey);
      setOpenAiOrgId(openAiOrgId);
    } catch (error) {
      console.error('Error fetching OpenAI config:', error);
    }
  };

  fetchOpenAiConfig();

  const getActivityIcon = (activity: any) => {
    switch (activity) {
      case 'Running':
      case 'running':
        return <DirectionsRunIcon />;
      case 'Cycling':
      case 'cycling':
        return <DirectionsBikeIcon />;
      case 'swimming':
      case 'Swimming':
        return <PoolIcon />;
      case 'strength':
      case 'Strength':
      case 'StrengthTraining':
        return <FitnessCenterIcon />;
      default:
        return <HelpIcon />;
    }
  };

  const handleDietModalOpen = () => {
    setDietModalOpen(true);
  };
  
  const handleDietModalClose = () => {
    setDietModalOpen(false);
  };
 
  const navigate = useNavigate();

  const sendMessageToChatGPT = async (message: string) => {
    const configuration = new Configuration({
      organization: openAiOrgId,
      apiKey: openAiAPIKey,
    });
  
    const openai = new OpenAIApi(configuration);

    const messages = [
      { role: ChatCompletionRequestMessageRoleEnum.System, content: 'You are a helpful assistant.' },
      ...chatMessages.map((chatMessage) => ({
        role: chatMessage.user === 'User' ? ChatCompletionRequestMessageRoleEnum.User : ChatCompletionRequestMessageRoleEnum.Assistant,
        content: chatMessage.message,
      })),
      { role: ChatCompletionRequestMessageRoleEnum.User, content: message },
    ];

    try {
      
      const response = await openai.createChatCompletion({
        model: 'gpt-4',
        messages: messages,
      });

      const chatGPTResponse = response.data.choices[0].message?.content;
      return chatGPTResponse;
    } catch (error:any) {
      createErrorLog({
        userId: localStorage.getItem('userId'),
        error: error,
        errorMessage: error.message,
      }).then(errorLog => {
          console.log('Error log created:', errorLog);
      }).catch(error => {
          console.error('Error creating error log:', error);
      });
      console.error('Error sending message to ChatGPT:', error);
      return 'Error communicating with AI Personal Trainer. Please try again';
    }
  };

const handleSendMessageClick = async () => {
  const messageInput = document.getElementById('chat-message-input') as HTMLInputElement;
  const message = messageInput.value;
  

  if (message.trim().length > 0) {
    const dailyChatCounter = await getDailyChatCounter();
    
    // if there's no chatCounter for the day, create a new one
    if (!dailyChatCounter) {
      await createChatCounter();  // assuming that userId is available in this context
    } 
    // if the chatCounter has reached or exceeded the limit, show a message and return from the function
    else if (dailyChatCounter.count >= 8) {
      setChatMessages((prevChatMessages) => [
        ...prevChatMessages,
        {
          message: 'You have asked the PT too many questions today. The limit is 8 questions requests per day for now. Please try again tomorrow.',
          user: 'System',
        },
      ]);
      return;
    } else {
      // Increment the chat counter and update it on the server
      dailyChatCounter.count++;
      await updateChatCounter(dailyChatCounter); // assuming updateChatCounter function takes a chat counter object as argument
    }

    messageInput.value = '';
    setChatMessages([...chatMessages, { message, user: 'User' }]);
    setIsLoading(true);

    let fullMessage = message;
    if (selectedButton === 'training' && todaysTrainingSessions) {
      const trainingSessionsList = JSON.stringify(todaysTrainingSessions, null, 2);
      fullMessage = `This is my training plan today: ${trainingSessionsList}. ${message}`;
    } else if (selectedButton === 'diet' && dietPlans) {
      const today = new Date().toISOString().split('T')[0];
      const todaysDietPlan = dietPlans.find(plan => plan.date.toString().split('T')[0] === today);

      if (todaysDietPlan) {
        const formattedDietPlan = JSON.stringify(todaysDietPlan, null, 2);
        fullMessage = `Today's meal plan is:\n${formattedDietPlan}`;
      } else {
        fullMessage = "Sorry, there's no meal plan for today.";
      }
    }
    const chatGPTResponse = await sendMessageToChatGPT(fullMessage);

    setChatMessages((prevChatMessages) => [
      ...prevChatMessages,
      {
        message: chatGPTResponse || 'Error communicating with AI Personal Trainer. Please try again',
        user: 'AI Personal Trainer',
      },
    ]);
    setSelectedButton(null);
    setIsLoading(false);
  }
};

const sendEWMAAndTrainingToChatGPT = async (trainingLoadData: DailyLoad[], trainingSessions: TrainingSessions[]) => {
  const dailyTrainingTipCounter = await getDailyTrainingTipCounter();

  // if there's no trainingTipCounter for the day, create a new one
  if (!dailyTrainingTipCounter) {
    await createTrainingTipCounter(); 
  } 
  // if the trainingTipCounter has reached or exceeded the limit, show a message and return from the function
  else if (dailyTrainingTipCounter.count >= 1) {
    setTrainingTip('You have requested too many training tips today. The limit is 1 requests per day. Please try again tomorrow.');
    setShowTip(true); 
    return;
  } else {
    // Increment the training tip counter and update it on the server
    dailyTrainingTipCounter.count++;
    await updateTrainingTipCounter(dailyTrainingTipCounter); 
  }

  setTipsLoading(true);
  // Find today's date and the date 7 days ago
  const today = new Date();
  today.setHours(0, 0, 0, 0);  // Set the time to midnight for accurate comparison
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  // Filter the last 7 days of EWMA ACWR and training sessions
  const last7EWMA = trainingLoadData.filter(item => item.date >= sevenDaysAgo && item.date <= today);
  const last7Sessions: any = trainingSessions.filter(session => {
    const sessionDate = new Date(session.date);
    return sessionDate >= sevenDaysAgo && sessionDate <= today && session.executed === true;
  });

  const reversedLast7EWMA = [...last7EWMA].reverse();
  const reversedLast7Sessions = [...last7Sessions].reverse();

  // Create strings to represent the last 7 days of EWMA ACWR and training sessions
  const ewmaString = reversedLast7EWMA.map(item => {
    const dateString = item.date.toISOString().split('T')[0]; 
    const ewmaAcwr = Number(item.ewmaAcwr).toFixed(2);
    return `Date: ${dateString}, Training Load: ${item.trainingLoad}, EWMA ACWR: ${ewmaAcwr}`;
    }).join('; ');   
  const sessionString = reversedLast7Sessions.map(session => {
    const sessionDate = new Date(session.date).toLocaleDateString();
    return `${sessionDate}: ${session.sessionDescription}` + 
      (session.intensityRatingOfPerceivedExertion !== null || session.intensityRatingOfPerceivedExertion !== undefined ? `. Rating of perceived exertion: "${session.intensityRatingOfPerceivedExertion}" of 10` : '') 
      }).join('\n');

  // Create the message to send to GPT
  const message = 
  `Todays date is: ${today}. This is my last 7 days of trainingloads and EWMA acute-to-chronic workload-ratio: [${ewmaString}]. 
  This is the executed trainingsessions from the last seven days: [${sessionString}]. 
  Give me a training recommendation for today in one short paragraph.`;
  // Send the message to GPT
  if(last7Sessions.length >= 3) {
    const trainingTip = await sendMessageToChatGPT(message);
    setTrainingTip(trainingTip || '');
  } else {
    setOpenTipDialogue(true);
  } 
 
 setTipsLoading(false);
 setShowTip(true); 
};

const formatLastWeekSessions = (sessions: TrainingSessions[]) => {
  return sessions.map(session => {
    const {
      date,
      activity,
      sessionTitle,
      sessionDescription,
      strengthTraining
    } = session;

    const strengthTrainingDetails = strengthTraining.map(st => {
      return `Exercise: ${st.exercise}, Repetitions: ${st.repetitions}, Load: ${st.load}`;
    }).join('\n');

    return `Date: ${date}, Activity: ${activity}, Session Title: ${sessionTitle}, Session Description: ${sessionDescription}, ${strengthTrainingDetails ? `, Strength Training: ${strengthTrainingDetails}` : ''}`;
  }).join('\n\n');
}; 


  useEffect(() => {
    const calculateDailyTrainingLoad = (sessions: TrainingSessions[]): DailyLoad[] => {
      const dailyLoadMap: { [key: string]: number } = {};
      const tenWeeksAgo = new Date();
      tenWeeksAgo.setDate(tenWeeksAgo.getDate() - 10 * 7);
    
      // Initialize all dates with zero training load
      for (let date = new Date(tenWeeksAgo); date <= new Date(); date.setDate(date.getDate() + 1)) {
        const dateString = date.toISOString().split('T')[0];
        dailyLoadMap[dateString] = 0;
      }
    
      // Add training load from sessions
      sessions.forEach(session => {
        const sessionDate = new Date(session.date); // Convert the session date to a JavaScript Date object
        const dateString = sessionDate.toISOString().split('T')[0];
        const duration = session.durationInMinutes || 0;
        const rpe = session.intensityRatingOfPerceivedExertion || 0;
        const trainingLoad = duration * rpe;
        dailyLoadMap[dateString] += trainingLoad;
      });
    
      return Object.entries(dailyLoadMap).map(([date, trainingLoad]) => ({
        date: new Date(date),
        trainingLoad,
      })).sort((a, b) => a.date.getTime() - b.date.getTime());  // Ensure the array is sorted by date
    };
  
    const calculateEWMA = (dailyLoads: DailyLoad[], N: number): DailyLoad[] => {
      const ewmaLoads: DailyLoad[] = dailyLoads.map(({date, trainingLoad}) => ({
        date,
        trainingLoad,
        ewma: 0,
      }));
    
      // Calculate lambda
      const lambda = 2 / (N + 1);
    
      // Calculate the EWMA for each day
      for (let i = 0; i < dailyLoads.length; i++) {
        if (i === 0) {
          ewmaLoads[i].ewma = dailyLoads[i].trainingLoad; // The first EWMA value is just the first training load
        } else {
          const previousEwma = ewmaLoads[i - 1].ewma || 0;
          const currentLoad = dailyLoads[i].trainingLoad || 0;
    
          // Calculate the EWMA using the formula
          ewmaLoads[i].ewma = lambda * currentLoad + (1 - lambda) * previousEwma;
        }
      }
    
      return ewmaLoads;
    };
    
    const calculateAcuteAndChronicLoads = (dailyLoads: DailyLoad[]): DailyLoad[] => {
      const acuteLoads = calculateEWMA(dailyLoads, 7); // N = 7 for acute loads
      const chronicLoads = calculateEWMA(dailyLoads, 28); // N = 28 for chronic loads
    
      // Merge the acute and chronic loads
      return dailyLoads.map((load, i) => ({
        ...load,
        ewmaAcute: acuteLoads[i].ewma,
        ewmaChronic: chronicLoads[i].ewma,
      }));
    };
  
    const calculateACWR = (dailyLoads: DailyLoad[]): DailyLoad[] => {
      return dailyLoads.map((dailyLoad) => {
          let ewmaAcwr;
          if (dailyLoad.ewmaAcute && dailyLoad.ewmaChronic) {
              ewmaAcwr = dailyLoad.ewmaAcute / dailyLoad.ewmaChronic;
          }
          return {
              ...dailyLoad,
              ewmaAcwr,
          };
      });
  };

    const fetchTrainingSessions = async () => {
      try {
        const fetchedTrainingSessions = await getUserTrainingSessionsLastTenWeeks();
  
        if (Array.isArray(fetchedTrainingSessions)) {
          setTrainingSessions(fetchedTrainingSessions);  // Store the fetched training sessions

          const dailyLoads = calculateDailyTrainingLoad(fetchedTrainingSessions);
          const dailyLoadsWithEWMAs = calculateAcuteAndChronicLoads(dailyLoads);
          const dailyLoadsWithACWR = calculateACWR(dailyLoadsWithEWMAs);

          setTrainingLoadData(dailyLoadsWithACWR);   
        } else {
          console.error('Fetched data is not an array:', fetchedTrainingSessions);
        }
      } catch (error) {
        console.error('Error fetching the training sessions:', error);
      }
    }; 
  
    const fetchDietPlans = async () => {
      try {
        const fetchedDietPlans = await getDietPlansbyUserId();
        if (Array.isArray(fetchedDietPlans)) {
          setDietPlans(fetchedDietPlans);
        } else {
          console.error('Fetched data is not an array:', fetchedDietPlans);
        }
      } catch (error) {
        console.error('Error fetching the diet plans:', error);
      }
    };
  
    fetchTrainingSessions();
    fetchDietPlans();
  }, [trainingSessionsUpdated]);

  useEffect(() => {
    fetchOpenAiConfig();
  }, []);

  useEffect(() => {
    const fetchLastWeekTrainingSessions = async () => {
      try {
        const fetchedLastWeekTrainingSessions = await getUserTrainingSessionsLastWeek();
  
        // Getting today's date
        const today = new Date();
        today.setHours(0, 0, 0, 0);
  
        // Filter today's sessions
        const todaysSessions = fetchedLastWeekTrainingSessions.filter((session: TrainingSessions) => {
          const sessionDate = new Date(session.date);
          sessionDate.setHours(0, 0, 0, 0);
          return sessionDate.getTime() === today.getTime();
        });
        
        const formattedTodaysSessions = formatLastWeekSessions(todaysSessions);
        setTodaysTrainingSessions(formattedTodaysSessions);
      } catch (error) {
        console.error('Error fetching the last week training sessions:', error);
      }
    };
  
    fetchLastWeekTrainingSessions();
  }, []);

  useEffect(() => {
    getsubscriptionStatus();
  }, []); 

  const handleAddIconClick = () => {
    setOpenAddModal(true);
  };

  const handleAddClose = () => {
    setOpenAddModal(false);
    setNewSession({
      date: new Date(),
      activity: '',
      sessionTitle: '',
      sessionDescription: '',
    });
  };

  const handleCreate = async () => {
    setLoading(true);
    try {
      // Set userId and executed values
      const sessionToCreate = {
        ...newSession,
        userId: localStorage.getItem('userId') || '',
        executed: true,
      };
  
      // Call the API to create the new training session
      const createdSession = await createTrainingSession(sessionToCreate);

      if (createdSession) {
        // Update the local state to include the newly created session
        setTrainingSessions((prevSessions) => [...prevSessions, createdSession]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      handleAddClose();
      // Reset the newSession state
      
    }
  };

  const handleOnSubmitTipDialoge = async () => {
    setTipsLoading(true);
    let message = '';
    if (trainingGoal.trim() !== '') message += `My training goal is ${trainingGoal}. `;
    message += `I am feeling ${feelingToday} today. `;
    if (activityToday.trim() !== '') message += `I would like to do ${activityToday} today. `;
    message += "Please provide one short paragraph of training recommendation for today.";
    
    const trainingTip = await sendMessageToChatGPT(message);
    setTrainingTip(trainingTip || '');
    setOpenTipDialogue(false);
    setTipsLoading(false);
    
  
    // Clearing the input fields after the submission
    setTrainingGoal('');
    setFeelingToday('');
    setActivityToday('');
  };

  const changeWeek = (direction: string) => {
    setSelectedWeek(
      direction === 'prev'
        ? subWeeks(selectedWeek, 1)
        : addWeeks(selectedWeek, 1),
    );
  };

  const renderWeeks = () => {
    const weeks = [];
    for (let i = 0; i < 3; i++) {
      const week = startOfWeek(subWeeks(new Date(), i), { weekStartsOn: 1 });
      weeks.push(
        <Button
          key={i}
          variant={
            getISOWeek(selectedWeek) === getISOWeek(week)
              ? 'contained'
              : 'outlined'
          }
          sx={{
            backgroundColor:
              getISOWeek(selectedWeek) === getISOWeek(week)
                ? 'primary.main'
                : 'default',
            color: getISOWeek(selectedWeek) === getISOWeek(week)
              ? 'primary.contrastText'
              : 'default',
          }}
          onClick={() => setSelectedWeek(week)}
        >
          {format(week, 'w')}
        </Button>,
      );
    }
    return weeks.reverse();
  };
  
  // Add a function to handle the running icon click
  const handleRunningIconClick = (session: TrainingSessions) => {
    setSelectedSession(session);
    setUpdatedSession(session);
    setOpen(true);
  };
  
  // Add a function to close the modal
  const handleClose = () => {
  setOpen(false);
  };
  
  const handleChatBubbleClick = () => {
  setChatWindowOpen(!chatWindowOpen);
  };
  
  const handleCloseChatWindow = () => {
  setChatWindowOpen(false);
  };
  
  const ChatWindow = () => {
    const chatWindowRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (chatWindowRef.current) {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
    }, [
      // chatMessages
    ]);
    return (
      <Box
        sx={{
          position: 'fixed',
          right: { xs: 0, sm: 16 },
          bottom: { xs: 0, sm: '3rem' },
          backgroundColor: 'background.paper',
          boxShadow: 3,
          borderRadius: '1rem',
          p: 2,
          width: { xs: '100%', sm: '75%' },
          maxWidth: '90%', // ensure that it doesn't exceed the screen width
          zIndex: 1,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h6">Chat with your AI Personal Trainer</Typography>
          <IconButton onClick={handleCloseChatWindow}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          ref={chatWindowRef}
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            maxHeight: chatMessages.length > 10 ? '50vh' : '200px',
            mb: 2,
            position: 'relative',
            p: 1,
          }}
        >
          {chatMessages.map((chatMessage, index) => (
            <Typography
              key={index}
              variant="body1"
              align={chatMessage.user === 'User' ? 'right' : 'left'}
              sx={{ mb: 1 }}
            >
              {chatMessage.user}: {chatMessage.message}
            </Typography>
          ))}
          {isLoading && (
            <Box
              sx={{
                position: 'fixed',
                bottom: '10px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={10}>
            <TextField
              id="chat-message-input"
              fullWidth
              variant="outlined"
              placeholder="Type your message"
              size="medium"
              multiline
              minRows={1}
              maxRows={4}
              sx={{ borderRadius: '0.5rem', backgroundColor: '#f5f5f5' }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessageClick();
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton disabled={disableButtonsBasedOnSubStatus} color="primary" onClick={handleSendMessageClick}>
              <SendIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 1,
            "& > button": {
              mb: 1,
            },
          }}
        >
          <Button
            variant={selectedButton === "training" ? "contained" : "outlined"}
            color={selectedButton === "training" ? "primary" : "inherit"}
            onClick={() => setSelectedButton(selectedButton === "training" ? null : "training")}
            fullWidth
          >
            Ask about todays training
          </Button>
          <Button
            variant={selectedButton === "diet" ? "contained" : "outlined"}
            color={selectedButton === "diet" ? "primary" : "inherit"}
            onClick={() => setSelectedButton(selectedButton === "diet" ? null : "diet")}
            fullWidth
          >
            Ask about todays diet plan
          </Button>
        </Box>
      </Box>
    );
  };

  function getNextMonday(): string {
    let date = new Date();
    const day = date.getDay();
    const difference = (day === 0) ? 1 : 8 - day;

    date.setDate(date.getDate() + difference);

    // Format the date to YYYY-MM-DD
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dayOfMonth = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${dayOfMonth}`;
    }

  function getWeekNumber(date: Date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  const handleCreateNextWeeksPlans = async (trainingSessions: any) => {
    function formatTrainingSessions(trainingSessions: TrainingSessions[]): string {
      // Get the date for 7 days ago
      const today = new Date();
      today.setHours(0, 0, 0, 0);  // Set the time to midnight for accurate comparison
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  
      return trainingSessions
          .filter((session) => session.executed === true && new Date(session.date) >= sevenDaysAgo)
          .map((session) => {
              const {
                  date,
                  activity,
                  sessionTitle,
                  sessionDescription,
                  executed,
                  feelingDuring,
                  intensityRatingOfPerceivedExertion,
                  distanceInKm,
              } = session;
      
              return `Date: ${date}, Activity: ${activity}, Title: ${sessionTitle}, Description: ${sessionDescription}, Executed: ${executed}, Feeling During: ${feelingDuring}, Intensity RPE: ${intensityRatingOfPerceivedExertion}, Distance (km): ${distanceInKm}`;
          }).join('\n');
    }

    try {
      const monthlyPlanCounter = await getMonthlyPlanCounters();
      // Check if the user has any planCounter for the current month
      if(monthlyPlanCounter.length === 0) {
        // If not, create a new planCounter
        await createPlanCounter(0, 0);
      } else if(monthlyPlanCounter.pressedNextWeekPlanningButtonCount >= 8) {
        // If the user has already pressed the button 8 or more times this month, show an alert and do not proceed
        alert("You have reached the monthly limit for this action (8). Please try again next month.");
        return;
      } else {
        // If the user has pressed the button less than 8 times this month, increment the counter and update the planCounter
        await updatePlanCounter(monthlyPlanCounter[0]._id, monthlyPlanCounter[0].trainingPlanCount, monthlyPlanCounter[0].dietPlanCount, monthlyPlanCounter[0].pressedNextWeekPlanningButtonCount + 1);
      }
  
    } catch (error:any) {
      console.error('Error fetching plancounter', error.message);
      alert(`Something went wrong. Please try again.`);
    }
  
    const nextMonday = getNextMonday();
    try {
      if(user) {
        setLoading(true);
        if (user.currentRunQuestionnaire) {
          const runQData = await getRunQuestionnairesById(user.currentRunQuestionnaire.toString());
          const { 
            goalDistance, 
            weekStartPlan, 
            averageKmPerWeekLastThreeMonths, 
            sessionPerWeekForPlan, 
            alternativeActivities,
            alternativeSessionsPerWeek, 
          } = runQData;

          const weeksIntoPlan = getWeekNumber(new Date()) - weekStartPlan;

          const inputString: string = 

          `IMPORTANT: Provide ONLY a valid JSON of the trainingSessions formatted like this in your answer:
          [
            {
            date: { type: Date (formatted like this: YYYY-MM-DD)},
            activity: { type: String, required: true },
            sessionTitle: { type: String, required: true },
            sessionDescription: { type: String }
          }
        ]
          IMPORTANT: The first item in the JSON shall start ${nextMonday}. Plan only one week.
          Context:
          In week ${weekStartPlan} I started this running plan:

          I have a goal of running ${goalDistance}k race. 
          The last three months this person has on average run ${averageKmPerWeekLastThreeMonths} km per week. 
          Take that into account when planning. 
          The person wants to train ${sessionPerWeekForPlan} sessions per week.
          The person also wants some alternative sessions with these activities ${alternativeSessionsPerWeek} times per week:
          ${alternativeActivities.length ? `Alternative activities: ${alternativeActivities.join(', ')}` : ''}

          I am now ${weeksIntoPlan} weeks into the original plan.
          This is my last week of training:
          ${formatTrainingSessions(trainingSessions)}
          Be specific in your description of the sessions.
          Rest days should NOT be included in the plan
          `;

          try {
            await createRunPlan(inputString);
            }

          catch (error: any) {
            createErrorLog({
              userId: localStorage.getItem('userId'),
              error: JSON.stringify(error),
              errorMessage: error.message,
              errorOrigin: "UserDashboard, RUN",
            }).then(errorLog => {
                console.log('Error log created:', errorLog);
            }).catch(error => {
                console.error('Error creating error log:', error);
            });
            console.error('Error creating run questionnaire:', error.message);
          }

        }
                
        if (user.currentStrengthQuestionnaire) {
          const strengthQData = await getStrengthQuestionnaireById(user.currentStrengthQuestionnaire.toString());
          const {
            strengthGoal,
            weekStartPlan,
            strengthSessionTypes,
            sessionsPerWeek,
            alternativeActivities: theseSelectedActivities,
            alternativeSessionsPerWeek,
          } = strengthQData;

          const weeksIntoPlan = getWeekNumber(new Date()) - weekStartPlan;

          const inputString: string = 
          `IMPORTANT: Provide no extra text in your answer other than a valid JSON of the trainingSessions formatted like this:
          [
            {
              "date": "YYYY-MM-DD",
              "activity": "String value required",
              "sessionTitle": "String value required",
              "sessionDescription": "String value (optional)",
              "strengthTraining": [
                {
                  "exercise": "String value",
                  "sets": 0,
                  "repetitions": 0
                }
              ]
            }
          ]
          IMPORTANT: The first item in the JSON shall start ${nextMonday}. Plan only one week.
          Context:
          In week ${weekStartPlan} I started a strength plan with these parameters:

          Make the first week of a strength-training plan for a person with a goal of ${strengthGoal}. 
          The person wants to train ${sessionsPerWeek} sessions per week and want these kind of sessions: ${strengthSessionTypes}.
          The person also wants some alternative sessions with these activities ${alternativeSessionsPerWeek} times per week:
          ${theseSelectedActivities.length ? `Alternative activities: ${theseSelectedActivities.join(', ')}` : ''}

          I am now ${weeksIntoPlan} weeks into the plan.
          These are my last week of training sessions:

          ${formatTrainingSessions(trainingSessions)}

          Be specific in your description of the sessions.
          Rest days should NOT be included in the plan.

          `;

          try {
            await createRunPlan(inputString);
          }

          catch (error: any) {
            createErrorLog({
              userId: localStorage.getItem('userId'),
              error: JSON.stringify(error),
              errorMessage: error.message,
              errorOrigin: "UserDashboard, Strength",
            }).then(errorLog => {
                console.log('Error log created:', errorLog);
            }).catch(error => {
                console.error('Error creating error log:', error);
            });
            console.error('Error creating run questionnaire:', error.message);
          }

        }
            
        if (user.currentDietPlanQuestionnaire !== null) {
          const dietQData = await getDietPlanQuestionnaireById(user.currentDietPlanQuestionnaire.toString());
          const {
            dietGoal,
            dietType,
            allergies,
            currentWeight,
            targetWeight,
            mealsPerDay,
          } = dietQData;

          const inputString: string = 
          `IMPORTANT: Provide no extra text in your answer other than a valid JSON of the trainingSessions formatted like this:
          [
            {
              "date": "YYYY-MM-DD",
              "dailyCalories": "",
              "meals": [
                {
                  "name": "MEALDESCRIPTION (be spesific in regards to amounts in ISO Units)",
                }
              ]
            }
          ]
          IMPORTANT: The first item in the JSON shall start ${nextMonday}. Plan only one week.
          Context:
          Make a mealplan for a week from ${nextMonday} for a person with a diet goal of ${dietGoal} weight and a diet type of ${dietType}. 
          The person wants to have ${mealsPerDay} meals per day.
          The person has the following allergies or food restrictions: ${allergies || 'None'}
          The person's current weight is ${currentWeight} kg, and their target weight is ${targetWeight} kg.
          `;

          try {
            await createDietPlan(inputString);
          }

          catch (error: any) {
            createErrorLog({
              userId: localStorage.getItem('userId'),
              error: JSON.stringify(error),
              errorMessage: error.message,
              errorOrigin: "UserDashboard, Dietplan",
            }).then(errorLog => {
                console.log('Error log created:', errorLog);
            }).catch(error => {
                console.error('Error creating error log:', error);
            });
            console.error('Error creating run questionnaire:', error.message);
          }
        }
      }
    } catch (error: any) {
      alert("Something went wrong. Please try again");
      console.error('Error creating next week plans:', error.message);
    } finally {
      setLoading(false);
      setTrainingSessionsUpdated(!trainingSessionsUpdated);
    }
  };
  
  
  const handleCreateNewDietPlanClick = () => {
  navigate('/dietQuestionnaire');
  };
  
  const handleCreateNewTrainingPlanClick = () => {
  navigate('/questionnaire');
  };

  const handleChange = (field: string, value: any) => {
    if (selectedSession) {
      setUpdatedSession({
        ...updatedSession ?? {},
        [field]: value,
      });
    }
  };
  
  const handleFeelingClick = (feeling: string) => {
    handleChange('feelingDuring', feeling);
  };
  
  const handleUpdate = async () => {
    if (selectedSession && updatedSession && selectedSession._id) {
      setLoading(true);
      const updatedSessionWithExecuted = { ...updatedSession, executed: true };
      try {
        const updated = await updateTrainingSessionById(selectedSession._id.toString(), updatedSessionWithExecuted);
        if (updated) {
          // Find the index of the updated session in the trainingSessions array
          const updatedSessionIndex = trainingSessions.findIndex(
            (session) => session._id === updated._id
          );
  
          // Replace the session at the found index with the updated session
          if (updatedSessionIndex > -1) {
            const newTrainingSessions = [...trainingSessions];
            newTrainingSessions[updatedSessionIndex] = updated;
            setTrainingSessions(newTrainingSessions);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        handleClose();
      }
    }
  };

  const handleDelete = async () => {
    if (selectedSession && selectedSession._id) {
      setLoading(true);
      try {
        const deleted = await deleteTrainingSessionById(selectedSession._id.toString() ?? '');
        if (deleted) {
          // Remove the deleted session from the trainingSessions array
          const newTrainingSessions = trainingSessions.filter(
            (session) => session._id !== selectedSession._id
          );
          setTrainingSessions(newTrainingSessions);
  
          // Close the modal
          
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        handleClose();
      }
    }
  };

  const handleDietDelete = async () => {
    if (selectedDietPlan && selectedDietPlan._id) {
      setLoading(true);
      try {
        const deleted = await deleteDietPlan(selectedDietPlan._id.toString() ?? '');
        if (deleted) {
          // Remove the deleted session from the trainingSessions array
          const newDietPlan = dietPlans.filter(
            (session) => session._id !== selectedDietPlan._id
          );
          setDietPlans(newDietPlan);
  
          // Close the modal
          
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        handleDietModalClose();
      }
    }
  };

  const getColor = (intensity: any) => {
    const r = Math.round(255 * intensity / 10);
    const g = Math.round(255 - (255 * intensity / 10));
    return `rgb(${r}, ${g}, 0)`;
  };

  const handleDietPlanIconClick = (dietPlan: DietPlan) => {
    setSelectedDietPlan(dietPlan);
    handleDietModalOpen();
  };
  
  const modalContainerStyles = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        paddingTop: '56px', // for mobile address bar
      },
    };

  const modalStyles = {
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      width: '100%',
      maxWidth: '80%',
      minWidth: '30%',
      overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 'calc(100% - 56px)', // subtract address bar height
        borderRadius: 0,
      },
    };

  return (
  <Container maxWidth="xs">
    <Box
      sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      pt: 2,
      }}
    >
  <Box
  sx={{
  display: 'flex',
  mb: 2,
  }}
  >
  <Button onClick={() => changeWeek('prev')}>Prev</Button>
  {renderWeeks()}
  <Button onClick={() => changeWeek('next')}>Next</Button>
  </Box>
  <Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button 
        onClick={() => {
          sendEWMAAndTrainingToChatGPT(trainingLoadData, trainingSessions) // Show the tip when the button is clicked
        }}
      >
        Get today's training tip
      </Button>
      {tipsLoading && 
        <Box sx={{ ml: 2 }}>
          <CircularProgress size={20} />
        </Box>
      }
    </Box>
    {showTip &&  // Only render the box if showTip is true
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 2,
          p: [2, 3, 4],
          backgroundColor: 'white', // light gray background
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // subtle shadow for depth
        }}
      >
        <Typography>{trainingTip}</Typography>
        <Button 
          onClick={() => setShowTip(false)}  // Hide the tip when the button is clicked
        >
          Hide today's training tip
        </Button>
      </Box>
      }
  </Box>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mb: 2,
      p: [2, 3, 4],
      backgroundColor: '#e0e0e0', // light gray background
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // subtle shadow for depth
    }}
  >
    {weekDays.map((day, index) => (
      <Box 
        key={index} 
        mb={2}
        sx={{
          width: '100%',
          borderRadius: '5px',
          p: 2,
          backgroundColor: 'white',
          boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)', // subtle shadow for depth
        }}
      >
        <Typography variant="h6">
          {format(day, 'EEEE, do MMMM')}
        </Typography>
        {trainingSessions
          .filter(
            (session) =>
              format(day, 'yyyy-MM-dd') ===
              format(new Date(session.date), 'yyyy-MM-dd'),
          )
          .map((session, sessionIndex) => (
            <IconButton
              key={sessionIndex}
              onClick={() => handleRunningIconClick(session)}
              sx={{
                mx: 1,
                backgroundColor: session.executed ? 'lightgreen' : 'initial',
                border: '1px solid #1976d2', // blue border
                '&:hover': {
                  backgroundColor: '#1976d2', // blue hover effect
                  color: 'white',
                },
              }}
            >
              {getActivityIcon(session.activity)}
            </IconButton>
          ))}
        {dietPlans
          .filter(
            (plan) =>
              format(day, 'yyyy-MM-dd') ===
              format(new Date(plan.date), 'yyyy-MM-dd'),
          )
          .map((plan, planIndex) => (
            <IconButton
              key={planIndex}
              onClick={() => handleDietPlanIconClick(plan)}
              sx={{ 
                mx: 1,
                border: '1px solid #1976d2', // blue border
                '&:hover': {
                  backgroundColor: '#1976d2', // blue hover effect
                  color: 'white',
                },
              }}
            >
              <RestaurantIcon />
            </IconButton>
          ))}
        <IconButton 
          onClick={handleAddIconClick} 
          sx={{ 
            mx: 1,
            border: '1px solid #1976d2', // blue border
            '&:hover': {
              backgroundColor: '#1976d2', // blue hover effect
              color: 'white',
            },
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
    ))}
  </Box>
  <Dialog open={openTipDialogue} onClose={() => setOpenTipDialogue(false)}>
    <DialogTitle>You have too few sessions recorded lately to give good training advice.</DialogTitle>
    <DialogContent>
      <DialogContentText>Please provide the following information:</DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="trainingGoal"
        label="Training Goal"
        type="text"
        fullWidth
        value={trainingGoal}
        onChange={e => setTrainingGoal(e.target.value)}
      />
      <FormControl component="fieldset">
        <FormLabel component="legend">How are you feeling today?</FormLabel>
        <RadioGroup row aria-label="feelingToday" name="feelingToday" value={feelingToday} onChange={e => setFeelingToday(e.target.value)}>
          <FormControlLabel value="poor" control={<Radio />} label="Poor" />
          <FormControlLabel value="ok" control={<Radio />} label="OK" />
          <FormControlLabel value="good" control={<Radio />} label="Good" />
          <FormControlLabel value="great" control={<Radio />} label="Great" />
        </RadioGroup>
      </FormControl>
      <TextField
        margin="dense"
        id="activityToday"
        label="Is there anything particular you want to do? (running, strength etc)"
        type="text"
        fullWidth
        value={activityToday}
        onChange={e => setActivityToday(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpenTipDialogue(false)}>Cancel</Button>
      <Button onClick={handleOnSubmitTipDialoge}>Submit</Button>
      {tipsLoading && 
        <Box sx={{ ml: 2 }}>
          <CircularProgress size={20} />
        </Box>
      }
    </DialogActions>
  </Dialog>
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="running-session-details"
    aria-describedby="running-session-details-details-description"
  >
    <Box
      sx={modalContainerStyles}
    >
      <Box sx={modalStyles}>
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: (theme:any) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography id="running-session-details" variant="h6" mb={2}>
        {selectedSession ? selectedSession.sessionTitle : ''}
      </Typography>
      <Typography id="running-session-details-description" variant="body1">
        {selectedSession ? selectedSession.sessionDescription : ''}
      </Typography>

      <Typography variant="body1" mt={2}>
        Log your details when executed and save the session.
      </Typography>

      {selectedSession && selectedSession.strengthTraining?.map((exercise, index) => (
          <Box key={index} mt={2}>
            <Typography variant="body1">
              {`${exercise.exercise}:`}
            </Typography>
            <Typography variant="body2">
              {`${exercise.sets} sets of ${exercise.repetitions} reps`}
            </Typography>
            
              </Box>
            ))}
          
        

      {selectedSession && (
        <>
          <Typography variant="body1" mt={2}>
            Intensity Rating of Perceived Exertion:
          </Typography>
          <ButtonGroup orientation="vertical" style={{ backgroundColor: "primary" }}
            aria-label="outlined primary button group">
            {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
              <Button
                key={num}
                onClick={() => handleChange('intensityRatingOfPerceivedExertion', num)}
                style={{
                  backgroundColor:
                    updatedSession?.intensityRatingOfPerceivedExertion === num ? getColor(num) : '',
                    color:
                  newSession.intensityRatingOfPerceivedExertion === num ? "#1a237e" : "#1a237e"
                }}
                >
                  {num === 1 ? '1: Very easy' : num === 10 ? '10: Maximal effort' : num}
                </Button>
              ))}
            </ButtonGroup>
            

          <TextField
            label="Duration in Minutes"
            type="number"
            value={updatedSession?.durationInMinutes??''}
            onChange={(e) => handleChange('durationInMinutes', Number(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Distance in KM"
            type="number"
            value={updatedSession?.distanceInKm??''}
            onChange={(e) => handleChange('distanceInKm', Number(e.target.value))}
            fullWidth
            margin="normal"
          />
          {/* Add input components for intensityZones and strengthTraining here */}

          <Typography variant="body1" mt={2}>
                Feeling During:
              </Typography>
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                {['poor', 'OK', 'great'].map((feeling) => (
                  <Button
                    key={feeling}
                    onClick={() => handleFeelingClick(feeling)}
                    style={{
                      backgroundColor: updatedSession?.feelingDuring === feeling ? '#4fc3f7' : '',
                    }}
                  >
                    {feeling}
                  </Button>
                ))}
              </ButtonGroup>
              {loading && (
                <Box mt={2} display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleUpdate}
                >
                  Log session
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={handleDelete}
                  sx={{ ml: 1 }}
                >
                  Delete session
                </Button>
              </Box>
            </>
          )}
          </Box>
        </Box>
  </Modal>
  <Modal
      open={openAddModal}
      onClose={handleAddClose}
      aria-labelledby="add-session-details"
      aria-describedby="add-session-details-description"
    >
      <Box
      sx={modalContainerStyles}
    >
      <Box sx={modalStyles}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleAddClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: (theme:any) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="add-session-details" variant="h6" mb={2}>
          Add New Training Session
        </Typography>

        {/* Add input fields for new training session properties */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              format="dd-MM-yyyy"
              value={newSession.date}
              onChange={(newValue: Date | null) => {
                if (newValue !== null) {
                  setNewSession({ ...newSession, date: newValue });
                }
              }}
              
              
            />
          </LocalizationProvider>
          <TextField
              label="Activity"
              select
              value={newSession.activity}
              onChange={(e) => setNewSession({ ...newSession, activity: e.target.value })}
              fullWidth
              margin="normal"
            >
              {/* Add options for activity here */}
              <MenuItem value="Running">Running</MenuItem>
              <MenuItem value="Cycling">Cycling</MenuItem>
              <MenuItem value="Swimming">Swimming</MenuItem>
              <MenuItem value="Strength">Strength</MenuItem>
              <MenuItem value="Circuit-training">Circuit training</MenuItem>
              <MenuItem value="Yoga">Yoga</MenuItem>
              <MenuItem value="Crossfit">Crossfit</MenuItem>
              <MenuItem value="Cross-country-skiing">Cross-country skiing</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
              {/* Add more activities as needed */}
            </TextField>
        <TextField
          label="Session Title"
          value={newSession.sessionTitle}
          onChange={(e) => setNewSession({ ...newSession, sessionTitle: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Session Description"
          value={newSession.sessionDescription}
          onChange={(e) => setNewSession({ ...newSession, sessionDescription: e.target.value })}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <Typography variant="body1" mt={2}>
          Intensity Rating of Perceived Exertion:
        </Typography>
        <ButtonGroup orientation="vertical" style={{ backgroundColor: "primary" }}
            aria-label="outlined primary button group">
          {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
            <Button
              key={num}
              onClick={() => setNewSession({ ...newSession, intensityRatingOfPerceivedExertion: num })}
              style={{
                backgroundColor:
                  newSession.intensityRatingOfPerceivedExertion === num ? getColor(num) : '',
                color:
                  newSession.intensityRatingOfPerceivedExertion === num ? "#1a237e" : "#1a237e"
                }}
                >
                  {num === 1 ? '1: Very easy' : num === 10 ? '10: Maximal effort' : num}
                </Button>
              ))}
              </ButtonGroup>
        <TextField
          label="Duration in Minutes"
          type="number"
          value={newSession.durationInMinutes}
          onChange={(e) => setNewSession({ ...newSession, durationInMinutes: Number(e.target.value) })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Distance in KM"
          type="number"
          value={newSession.distanceInKm}
          onChange={(e) => setNewSession({ ...newSession, distanceInKm: Number(e.target.value) })}
          fullWidth
          margin="normal"
        />
        <Typography variant="body1" mt={2}>
          Feeling During:
        </Typography>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          {['poor', 'OK', 'great'].map((feeling) => (
            <Button
              key={feeling}
              onClick={() => setNewSession({ ...newSession, feelingDuring: feeling })}
              style={{
                backgroundColor: newSession.feelingDuring === feeling ? '#4fc3f7' : '',
              }}
            >
              {feeling}
            </Button>
          ))}
        </ButtonGroup>

        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreate}
          >
            Create session
          </Button>
        </Box>
        </Box>
      </Box>
    </Modal>
    <Modal
      open={dietModalOpen}
      onClose={handleDietModalClose}
      aria-labelledby="diet-plan-details"
      aria-describedby="diet-plan-details-description"
    >
      <Box
      sx={modalContainerStyles}
    >
      <Box
    sx={modalStyles}
  >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleDietModalClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="diet-plan-details" variant="h6" mb={2}>
            {selectedDietPlan && `Diet Plan for ${format(new Date(selectedDietPlan.date), 'EEEE, do MMMM')}`}
          </Typography>
          <Typography variant="body1" mt={2}>
            {selectedDietPlan && `Daily Calories: ${selectedDietPlan.dailyCalories}`}
          </Typography>
          {selectedDietPlan && selectedDietPlan.meals.map((meal, index) => (
          <Box key={index} mt={2}>
            <Typography variant="h6">{meal.name}</Typography>                  
          </Box>
        ))}
        <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={handleDietDelete}
            sx={{ ml: 1 }}
          >
            Delete
          </Button>
      </Box>
      </Box>
    </Modal>
  <IconButton
    color="primary"
    sx={{
    position: 'fixed',
    right: '1rem',
    bottom: '1rem',
    zIndex: 1,
    boxShadow: '0 3px 5px 2px rgba(105, 105, 105, .7)',
    }}
    onClick={handleChatBubbleClick}
    >
  <BiMessageSquareDetail size={48} />
  </IconButton>
  {chatWindowOpen && <ChatWindow />}
  </Box>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      my: 2,
      zIndex: 0, // Set the zIndex value to 0 or any other value lower than the chat window's zIndex
    }}
  >
    {showButtonsBasedOnCurrQ && <Button
      disabled={disableButtonsBasedOnSubStatus && disabledIfNotSunday}
      variant="contained"
      color="info"
      onClick={() => handleCreateNextWeeksPlans(trainingSessions)}
      sx={{ width: '100%', my: 1 }}
    >
      Create next weeks plan
    </Button>}
    {loading && 
        <Box sx={{ ml: 2 }}>
          <CircularProgress size={20} />
        </Box>
      }
    {showButtonsBasedOnCurrQ && <Typography variant="body2" align="center" color="textSecondary">
      Remember to create next weeks plan before sunday evening.
    </Typography>}
    <Button
      disabled={disableButtonsBasedOnSubStatus}
      variant="contained"
      color="info"
      onClick={handleCreateNewDietPlanClick}
      sx={{ width: '100%', my: 1 }}
    >
      Create new diet plan
    </Button>
    <Button
      disabled={disableButtonsBasedOnSubStatus}
      variant="contained"
      color="info"
      onClick={handleCreateNewTrainingPlanClick}
      sx={{ width: '100%', my: 1 }}
    >
      Create new training plan
    </Button>
  </Box>
  <Typography variant="body2" align="left" color="textSecondary">
    Copyright AP-Performance Solutions AS 2023
  </Typography>
  </Container>
  );
  };
  
  export default UserDashboard;
