import React from "react";
import { Button, Container, Typography, Box, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Questionnaire = () => {
  const navigate = useNavigate();

  const handleStrengthClick = () => {
    navigate("/strengthQuestionnaire");
  };

  const handleRunningClick = () => {
    navigate("/runQuestionnaire");
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Paper elevation={3} sx={{ padding: '2em', marginTop: '1em' }}>
          <Typography variant="h4" align="center" gutterBottom>
            Welcome to the Questionnaire Page!
          </Typography>
          <Typography variant="h6" align="center" gutterBottom>
            What do you want a custom plan for?
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleStrengthClick}
              sx={{
                m: 1,
                border: "1px solid #1976d2",
                "&:hover": {
                  backgroundColor: "#1976d2",
                  color: "white",
                },
                width: '80%', // full width of parent
                maxWidth: '200px', // or any maximum width you want to set
              }}
            >
              Strength focused plan
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRunningClick}
              sx={{
                m: 1,
                border: "1px solid #1976d2",
                "&:hover": {
                  backgroundColor: "#1976d2",
                  color: "white",
                },
                width: '80%', // full width of parent
                maxWidth: '200px', // or any maximum width you want to set
              }}
            >
              Running focused plan
            </Button>
          </Box>
        </Paper>
        <Typography variant="body2" align="left" color="textSecondary" sx={{ mt: 2 }}>
          Copyright AP-Performance Solutions AS 2023
        </Typography>
      </Box>
    </Container>
  );
};

export default Questionnaire;
