import React, { useState } from 'react';
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Button,
  Box,
} from '@mui/material';

const TriathlonQuestionnaire = () => {
  const [triathlonType, setTriathlonType] = useState('');
  const [swimmingExperience, setSwimmingExperience] = useState('');
  const [cyclingExperience, setCyclingExperience] = useState('');
  const [runningExperience, setRunningExperience] = useState('');
  const [sessionsPerWeek, setSessionsPerWeek] = useState('');
  const [weeks, setWeeks] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(
      triathlonType,
      swimmingExperience,
      cyclingExperience,
      runningExperience,
      sessionsPerWeek,
      weeks
    );
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Triathlon Questionnaire
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">Triathlon Type</FormLabel>
          <RadioGroup
            value={triathlonType}
            onChange={(event) => setTriathlonType(event.target.value)}
          >
            <FormControlLabel value="sprint" control={<Radio />} label="Sprint" />
            <FormControlLabel value="olympic" control={<Radio />} label="Olympic" />
            <FormControlLabel value="half" control={<Radio />} label="Half Ironman" />
            <FormControlLabel value="full" control={<Radio />} label="Ironman" />
          </RadioGroup>
        </FormControl>
        <TextField
          label="Swimming experience (hours per week)"
          type="number"
          fullWidth
          value={swimmingExperience}
          onChange={(event) => setSwimmingExperience(event.target.value)}
        />
        <TextField
          label="Cycling experience (hours per week)"
          type="number"
          fullWidth
          value={cyclingExperience}
          onChange={(event) => setCyclingExperience(event.target.value)}
        />
        <TextField
          label="Running experience (hours per week)"
          type="number"
          fullWidth
          value={runningExperience}
          onChange={(event) => setRunningExperience(event.target.value)}
        />
        <TextField
          label="Total sessions per week for training plan"
          type="number"
          fullWidth
          value={sessionsPerWeek}
          onChange={(event) => setSessionsPerWeek(event.target.value)}
        />
        <TextField
          label="Weeks until goal or competition"
          type="number"
          fullWidth
          value={weeks}
          onChange={(event) => setWeeks(event.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
      </Box>
    </Container>
  );
};

export default TriathlonQuestionnaire;
