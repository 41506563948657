import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Checkbox,
  // FormGroup,
  IconButton,
  Paper,
  Grid,
  LinearProgress
} from "@mui/material";
import { createRunPlan, createStrengthQuestionnaire, createErrorLog, updateUser,
  getMonthlyPlanCounters, createPlanCounter, updatePlanCounter } from './../api';
import AddIcon from '@mui/icons-material/Add';

type AlternativeActivitiesType = {
  [key: string]: boolean;
};
type StrengthSessionTypesType = {
  [key: string]: boolean;
};

const StrengthQuestionnaire = () => {
  const [strengthGoal, setStrengthGoal] = useState('');
  const [experienceLevel, setExperienceLevel] = useState('');
  const [strengthSessionsPerWeek, setStrengthSessionsPerWeek] = useState('');
  const [alternativeSessionsPerWeek, setAlternativeSessionsPerWeek] = useState('');
  const [strengthSessionType, setStrengthSessionType] = useState<StrengthSessionTypesType>({
    freeweights: false,
    machines: false,
    bodyweightExercises: false,
  });
  const [alternativeActivities, setAlternativeActivities] = useState<AlternativeActivitiesType>({
    running: false,
    cycling: false,
    swimming: false,
    hiking: false,
    skisOrRolleskis: false,
    assaultbike: false,
  });
  const [customActivity, setCustomActivity] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function getPreviousMonday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysSinceLastMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

    // If today is Monday, return today's date
    if (daysSinceLastMonday === 0) {
      return today;
    }

    const previousMonday = new Date(today);
    previousMonday.setDate(today.getDate() - daysSinceLastMonday);
    return previousMonday.toISOString().slice(0,10);
  }

  const previousMonday = getPreviousMonday();

  function getNextSunday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysUntilNextSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
  
    const nextSunday = new Date(today);
    nextSunday.setDate(today.getDate() + daysUntilNextSunday);
  
    return nextSunday.toISOString().slice(0,10); // returns date in YYYY-MM-DD format
  }
  
  const nextSunday = getNextSunday();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxGroup =
      event.target.name in strengthSessionType ? setStrengthSessionType : setAlternativeActivities;
    checkboxGroup((prevState: AlternativeActivitiesType) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleCheckboxChangeStrength = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxGroup =
      event.target.name in strengthSessionType ? setStrengthSessionType : setAlternativeActivities;
    checkboxGroup((prevState: StrengthSessionTypesType) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const addCustomActivity = () => {
    setAlternativeActivities({
      ...alternativeActivities,
      [customActivity]: true,
    });
    setCustomActivity('');
  };

  function getWeekNumber(date: Date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const theseSelectedActivities = Object.keys(alternativeActivities).filter(
      (activity) => alternativeActivities[activity]
    );
  
    const selectedStrengthSessionTypes = Object.keys(strengthSessionType).filter(
      (type) => strengthSessionType[type]
    );
  
    // Convert arrays into strings
    const selectedStrengthSessionTypesString = selectedStrengthSessionTypes.join(', ');
    const theseSelectedActivitiesString = theseSelectedActivities.length 
      ? `The person also wants some alternative sessions with these activities ${alternativeSessionsPerWeek} times per week: Alternative activities: ${theseSelectedActivities.join(', ')}`
      : '';
  
    console.log(selectedStrengthSessionTypes);
    console.log(theseSelectedActivities);
  
    const strengthQuestionnaireData = {
      userId: localStorage.getItem('userId'),
      weekStartPlan: getWeekNumber(new Date()),
      strengthGoal,
      strengthSessionTypes: selectedStrengthSessionTypes,
      experienceLevel,
      strengthSessionsPerWeekForPlan: parseInt(strengthSessionsPerWeek),
      alternativeActivities: theseSelectedActivities,
      alternativeSessionsPerWeek: alternativeSessionsPerWeek,
    };
  
    const inputString: string = `
    IMPORTANT: Provide no extra text in your answer other than the JSON of the trainingSessions formatted like this:
    [
      {
        "date": "YYYY-MM-DD",
        "activity": "String",
        "sessionTitle": "String",
        "sessionDescription": "String",
        "strengthTraining": [
          {
            "exercise": "String",
            "sets": number,
            "repetitions": number
          }
        ]
      }
    ]
    IMPORTANT: PROVIDE THE EXERCISE, SETS & REPETITIONS, OR AN EMPTY strengthTraining[].
    Be specific in your description of the sessions.
    Rest days should NOT be included in the plan
    Context:
    Make a strength-training from ${previousMonday} until ${nextSunday} for a person with a goal of ${strengthGoal} and this experiencelevel: ${experienceLevel}.
    The person wants to train ${strengthSessionsPerWeek} strengthsessions per week and want to use this kind of equipment ${selectedStrengthSessionTypesString}.
    ${theseSelectedActivitiesString}
    `;

    try {
      const monthlyPlanCounter = await getMonthlyPlanCounters();
  
      if (monthlyPlanCounter.length === 0) {
        await createPlanCounter(1, 0);
      } else if (monthlyPlanCounter.trainingPlanCount >= 4) {
        alert("You have created more than four plans this month. Please try again next month. In the meantime you can use the chat- and tips-function to request training advice");
        setLoading(false);
        return;
      } else {
        await updatePlanCounter(monthlyPlanCounter[0]._id, 
          monthlyPlanCounter[0].trainingPlanCount + 1, 
          monthlyPlanCounter[0].dietPlanCount, 
          monthlyPlanCounter[0].pressedNextWeekPlanningButtonCount);
      }
  
      // Continue with the submit
      const response = await createRunPlan(inputString);
      if (response) {
        const strengthQuestionnaireResponseDate = await createStrengthQuestionnaire(strengthQuestionnaireData);
        if (strengthQuestionnaireResponseDate) {
          console.log("Created strengthQ, updating user");
          try {
            console.log(strengthQuestionnaireResponseDate._id);
            await updateUser(localStorage.getItem('userId') || '', { currentStrengthQuestionnaire: strengthQuestionnaireResponseDate._id })
          } catch (error) {
            console.error('Error updating user:', error);
          }
        }
      }
      navigate('/');
    } catch (error: any) {
      createErrorLog({
        userId: localStorage.getItem('userId'),
        error: JSON.stringify(error),
        errorMessage: error.message,
        errorOrigin: "StrengthQuestionnaire",
      }).then(errorLog => {
        console.log('Error log created:', errorLog);
      }).catch(error => {
        console.error('Error creating error log:', error);
      });
      console.error('Error creating run questionnaire:', error.message);
      setLoading(false);
      alert(`Something went wrong. Please try again.`);
    }
  };

  const activityList = Object.keys(alternativeActivities);

  return (
    <Container component="main" maxWidth="xs">
    <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Paper elevation={3} sx={{ padding: '2em', marginTop: '1em' }}>
        <Typography variant="h4" align="center" gutterBottom>
          Strength Questionnaire
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Main strengthtraining goal?</FormLabel>
            <RadioGroup
              value={strengthGoal}
              onChange={(event) => setStrengthGoal(event.target.value)}
            >
              <FormControlLabel value="building strength" control={<Radio />} label="Strength" />
              <FormControlLabel value="increasing muscle mass" control={<Radio />} label="Hypertrophy (increase muscle mass)" />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">What type of strength session would you like?</FormLabel>
            {Object.keys(strengthSessionType).map((activity) => (
              <FormControlLabel
                key={activity}
                control={
                  <Checkbox
                    checked={strengthSessionType[activity]}
                    onChange={handleCheckboxChangeStrength}
                    name={activity}
                  />
                }
                label={activity}
              />
            ))}
          </FormControl>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Experience Level</FormLabel>
            <RadioGroup
              value={experienceLevel}
              onChange={(event) => setExperienceLevel(event.target.value)}
            >
              <FormControlLabel value="beginner" control={<Radio />} label="Beginner" />
              <FormControlLabel value="intermediate" control={<Radio />} label="Intermediate" />
              <FormControlLabel value="advanced" control={<Radio />} label="Advanced" />
            </RadioGroup>
          </FormControl>
          <TextField
            label="Strengthsessions per week?"
            type="number"
            fullWidth
            margin="normal"
            value={strengthSessionsPerWeek}
            onChange={(event) => setStrengthSessionsPerWeek(event.target.value)}
          />
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              Do you want some alternative activities as well? Check the boxes of the activity you would like:
            </FormLabel>
            {activityList.map((activity) => (
              <FormControlLabel
                key={activity}
                control={
                  <Checkbox
                    checked={alternativeActivities[activity]}
                    onChange={handleCheckboxChange}
                    name={activity}
                  />
                }
                label={activity}
              />
            ))}
            <Grid container justifyContent="start" alignItems="center" spacing={2}>
              <Grid item xs={9}>
                <TextField
                  label="Add another activity"
                  value={customActivity}
                  onChange={(event) => setCustomActivity(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <IconButton onClick={addCustomActivity} disabled={!customActivity} size="small">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          </FormControl>
          <TextField
            label="Sessions per week for other activities"
            type="number"
            fullWidth
            margin="normal"
            value={alternativeSessionsPerWeek}
            onChange={(event) => setAlternativeSessionsPerWeek(event.target.value)}
          />
          {loading && (
            <Box mt={2}>
              <LinearProgress />
              <Typography variant="subtitle1" align="center">
                Creating your personalized plan... This may take a minute or two. Be patient.
              </Typography>
            </Box>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            sx={{
              border: '1px solid #1976d2',
              '&:hover': {
                backgroundColor: '#1976d2',
                color: 'white',
              },
              mt: 2,
            }}
          >
            Submit
          </Button>
        </Box>
        <Typography variant="body2" align="left" color="textSecondary" sx={{ mt: 2 }}>
          &copy; AP-Performance Solutions AS 2023
        </Typography>
      </Paper>
    </Box>
  </Container>
  );
};

export default StrengthQuestionnaire;
