import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Box, Typography, Button, Container, Card, CardContent, CardActions } from '@mui/material';
import { getUserById, createStripeCheckoutSession, cancelSubscription } from './../api';
import { User } from '../models/user';
import { initializeApp } from 'firebase/app';

const stripePromise = loadStripe('your_stripe_publishable_key');

const PaymentPage = () => {
  const plans = [
    { id: 'plan1', name: 'AI Personal Trainer', price: 199 },
  ];

  const [userHasActiveSubscription, setUserHasActiveSubscription] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const subscriptionType = 'basic';
  const [stripePubKey, setStripePubKey] = useState<string>('');
  // const [subscriptionType, setSubscriptionType] = useState('basic');

  const firebaseConfig = {
    // Your firebase config object
  };
  initializeApp(firebaseConfig);
  
  const fetchStripePubKey = async () => {
    try {
      const response = await fetch("https://us-central1-ai-personal-trainer-386016.cloudfunctions.net/getStripePublishableKey");
      const data = await response.json();
      const { stripePublishableKey } = data;
      setStripePubKey(stripePublishableKey);
    } catch (error) {
      console.error('Error fetching Stripe config:', error);
    }
  };
  
  fetchStripePubKey();

  // Fetch user data when the component is loaded
  useEffect(() => {
    const fetchUserData = async () => {
      // Assume you have the user's ID
      const userId = localStorage.getItem('userId')

      if (userId !== null) {
        const user = await getUserById(userId);
        setUser(user);
        setUserHasActiveSubscription(user.activeSubscription);
      } else {
        console.error('User ID not found in local storage');
        // handle the situation when userId is not found
      }
    };

    fetchUserData();
  }, []);

  const handleSubscription = async () => {
      // const userId = localStorage.getItem('userId')
      if (userHasActiveSubscription) {
        if (user && user.stripeSubscriptionId) {
          // Cancel the subscription using the stored subscription ID
          try {
            await cancelSubscription(user.stripeSubscriptionId);
            alert("Your subscription has been cancelled.");

            // Fetch the user data again
            const userId = localStorage.getItem('userId')
            if (userId !== null) {
              const updatedUser = await getUserById(userId);
              setUser(updatedUser);
              setUserHasActiveSubscription(updatedUser.activeSubscription);
            } else {
              console.error('User ID not found in local storage');
              // handle the situation when userId is not found
            }

          } catch (error) {
            console.error('Error cancelling subscription:', error);
          }
        } else {
          console.error('Subscription ID not available');
        }
      } else {
        if (user) {
          try {
            const session = await createStripeCheckoutSession(user.email, user.firstName, user.lastName, subscriptionType);
            const stripe = await (window as any).Stripe(stripePubKey);
            await stripe.redirectToCheckout({ sessionId: session.id });
          } catch (error) {
            console.error('Error creating Stripe checkout session:', error);
          } finally {
            const userId = localStorage.getItem('userId')
            if (userId !== null) {
              const updatedUser = await getUserById(userId);
              setUser(updatedUser);
              setUserHasActiveSubscription(updatedUser.activeSubscription);
            } else {
              console.error('User ID not found in local storage');
              // handle the situation when userId is not found
            }
          }
        } else {
          console.error('User data not available');
        }
      }
    };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Subscription Plans
        </Typography>

        <Elements stripe={stripePromise}>
          {plans.map((plan) => (
            <Card key={plan.id} sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {plan.name} - NOK {plan.price},- /month (around 19USD)
                </Typography>
              </CardContent>
              <CardActions>
                {/* Stripe Checkout button */}
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleSubscription}
                >
                  {userHasActiveSubscription ? 'Cancel Subscription' : 'Subscribe'}
                </Button>
              </CardActions>
            </Card>
          ))}
        </Elements>
      </Box>
      <Typography variant="body2" align="left" color="textSecondary">
          Copyright AP-Performance Solutions AS 2023
        </Typography>
    </Container>
  );
};

export default PaymentPage;
