import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  FormControl,
  FormLabel,
//   RadioGroup,
  FormControlLabel,
//   Radio,
  TextField,
  Button,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  Paper
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { createEnduranceQuestionnaire, updateUser, getMonthlyPlanCounters, createPlanCounter,updatePlanCounter } from '../api';
import { createRunPlan, createErrorLog } from './../api';
import AddIcon from '@mui/icons-material/Add';

type ActivitiesType = {
running: boolean;
  cycling: boolean;
  swimming: boolean;
  hiking: boolean;
  skisOrRolleskis: boolean;
  assaultbike: boolean;
  rowing: boolean;
  [key: string]: boolean;
};

type AlternativeActivitiesType = {
    strengthWithFreeweights: boolean;
    strengthWithMachines: boolean;
    strengthWithBodyweightExercises: boolean;
    [key: string]: boolean;
  };

const EnduranceQuestionnaire = () => {
  const [goal, setGoal] = useState('');
  const [trainingHistory, setTrainingHistory] = useState('');
  const [sessionsPerWeek, setSessionsPerWeek] = useState('');
  const [alternativeSessionsPerWeek, setAlternativeSessionsPerWeek] = useState('');
  const [loading, setLoading] = useState(false);

  const [activities, setActivities] = useState<ActivitiesType>({
    running: false,
    cycling: false,
    swimming: false,
    hiking: false,
    skisOrRolleskis: false,
    assaultbike: false,
    rowing: false,
  });

  const [alternativeActivities, setAlternativeActivities] = useState<AlternativeActivitiesType>({
    strengthWithFreeweights: false,
    strengthWithMachines: false,
    strengthWithBodyweightExercises: false,
  });

  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const [selectedAlternativeActivities, setSelectedAlternativeActivities] = useState<string[]>([]);

  const activityKeys: Record<string, string> = {
    'Running': 'running',
    'Cycling': 'cycling',
    'Swimming': 'swimming',
    'Hiking': 'hiking',
    'Cross-country-skiing or rollerskiing': 'skisOrRolleskis',
    'Assaultbike': 'assaultbike',
    'Rowing': 'rowing',
  };

  const alternativeActivityKeys: Record<string, keyof AlternativeActivitiesType> = {
    'Strength with freeweights': 'strengthWithFreeweights',
    'Strength with machines': 'strengthWithMachines',
    'Strength with bodyweight exercises': 'strengthWithBodyweightExercises',
  };

  const [customActivity, setCustomActivity] = useState('');
  const [customActivities, setCustomActivities] = useState<string[]>([]);

  const [customAlternativeActivity, setCustomAlternativeActivity] = useState('');
  const [customAlternativeActivities, setCustomAlternativeActivities] = useState<string[]>([]);


  const navigate = useNavigate();

  function getPreviousMonday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysSinceLastMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

    // If today is Monday, return today's date
    if (daysSinceLastMonday === 0) {
      return today;
    }

    const previousMonday = new Date(today);
    previousMonday.setDate(today.getDate() - daysSinceLastMonday);
    return previousMonday.toISOString().slice(0,10);
  }

  const previousMonday = getPreviousMonday();

  function getNextSunday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysUntilNextSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
  
    const nextSunday = new Date(today);
    nextSunday.setDate(today.getDate() + daysUntilNextSunday);
  
    return nextSunday.toISOString().slice(0,10); // returns date in YYYY-MM-DD format
  }
  
  const nextSunday = getNextSunday();
  

  function getWeekNumber(date: Date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  const handleCheckboxChangeActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const activityName = event.target.name;
    const isChecked = event.target.checked;
  
    setActivities((prevActivities) => ({
      ...prevActivities,
      [activityName]: isChecked,
    }));
  
    setSelectedActivities((prevSelectedActivities) => {
      if (isChecked) {
        return [...prevSelectedActivities, activityName];
      } else {
        return prevSelectedActivities.filter((name) => name !== activityName);
      }
    });
  };
  

  const handleCheckboxChangeAlternativeActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const activityName = event.target.name;
    const isChecked = event.target.checked;
    setAlternativeActivities({
      ...alternativeActivities,
      [activityName]: isChecked,
    });
    setSelectedAlternativeActivities((prevSelectedActivities) => {
      if (isChecked) {
        return [...prevSelectedActivities, activityName];
      } else {
        return prevSelectedActivities.filter((name) => name !== activityName);
      }
    });
  };

  const handleAddCustomActivity = () => {
    if (customActivity.trim()) {
      setCustomActivities([...customActivities, customActivity.trim()]);
      setCustomActivity('');
    }
  };

  const handleAddCustomAlternativeActivity = () => {
    if (customAlternativeActivity.trim()) {
      setCustomAlternativeActivities([...customAlternativeActivities, customAlternativeActivity.trim()]);
      setCustomAlternativeActivity('');
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    const enduranceQuestionnaireData = {
      userId: localStorage.getItem('userId'),
      goal: goal,
      averageSessionsPerWeekLastThreeMonths: parseInt(sessionsPerWeek),
      sessionPerWeekForPlan: parseInt(sessionsPerWeek),
      weekStartPlan: getWeekNumber(new Date()),
      activities: selectedActivities,
      alternativeActivities: selectedAlternativeActivities,
      alternativeSessionsPerWeek: parseInt(alternativeSessionsPerWeek),
    };

    const theseSelectedActivities = Object.keys(activities).filter(
      (activity) => activities[activity]
    );

    const theseSelectedAlternativeActivities = Object.keys(alternativeActivities).filter(
        (activity) => alternativeActivities[activity]
      );

    const inputString: string =
    `IMPORTANT: Provide no extra text in your answer other than the JSON of the trainingSessions formatted like this:
    [{
      "date": "YYYY-MM-DD",
      "activity": "String",
      "sessionTitle": "String",
      "sessionDescription": "String",
    }]
    Context:
    Make the first week of a reasonable training plan from ${previousMonday} until ${nextSunday} for a person with this goal: ${goal}.
    The person wants to train ${sessionsPerWeek} sessions per week in total with these activities: ${theseSelectedActivities.join(', ')}
    He/she has exercised ${trainingHistory} sessions per week on average the last three months. Allways take their prerequisites into account when planning.
    ${theseSelectedAlternativeActivities.length ? `The person also wants some ${theseSelectedAlternativeActivities.join(', ')}, ${alternativeSessionsPerWeek} times per week` : ''}
    Be specific in your description of the sessions.
    Rest days should NOT be included in the plan
    `;

    try {
      const monthlyPlanCounter = await getMonthlyPlanCounters();
  
      if (monthlyPlanCounter.length === 0) {
        await createPlanCounter(1, 0);
      } else if (monthlyPlanCounter.trainingPlanCount >= 4) {
        alert("You have created more than four plans this month. Please try again next month. In the meantime you can use the chat- and tips-function to request training advice");
        setLoading(false);
        return;
      } else {
        await updatePlanCounter(monthlyPlanCounter[0]._id, 
          monthlyPlanCounter[0].trainingPlanCount + 1, 
          monthlyPlanCounter[0].dietPlanCount, 
          monthlyPlanCounter[0].pressedNextWeekPlanningButtonCount);
      }
    
      // Continue with the submit
      const response = await createRunPlan(inputString);
      if(response) {
        const enduranceQuestionnaireDataResponse = await createEnduranceQuestionnaire(enduranceQuestionnaireData);
        if(enduranceQuestionnaireDataResponse) {
          try {

            await updateUser(localStorage.getItem('userId') || '', { currentRunQuestionnaire: enduranceQuestionnaireDataResponse._id } )
          } catch (error) {
            console.error('Error updating user:', error);
          }
        }
      }
      navigate('/');
      
    } catch (error: any) {
      createErrorLog({
        userId: localStorage.getItem('userId'),
        error: JSON.stringify(error),
        errorMessage: error.message,
        errorOrigin: "RunQuestionnaire",
      }).then(errorLog => {
          console.log('Error log created:', errorLog);
      }).catch(error => {
          console.error('Error creating error log:', error);
      });
      console.error('Error creating run questionnaire:', error.message);
      setLoading(false);
      alert(`Something went wrong. Please try again.`);
    }

  };


  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Paper elevation={3} sx={{ padding: '2em', marginTop: '1em' }}>
          <Typography variant="h4" align="center" gutterBottom>
            Endurance Questionnaire
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
          <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">
                    Which activities do you want in your plan?
                </FormLabel>
                {[
                    'Running',
                    'Cycling',
                    'Swimming',
                    'Hiking',
                    'Cross-country-skiing or rollerskiing',
                    'Assaultbike',
                    'Rowing'
                ].map((activity) => (
                    <FormControlLabel
                    key={activity}
                    control={
                        <Checkbox
                            checked={activities[activityKeys[activity]]}
                            onChange={handleCheckboxChangeActivity}
                            name={activityKeys[activity]}
                        />
                    }
                    label={activity.replace(/([A-Z])/g, ' $1').trim()}
                />
                ))}
                {customActivities.map((activity) => (
                    <FormControlLabel
                    key={activity}
                    control={<Checkbox checked disabled />}
                    label={activity}
                    />
                ))}
                    <Grid container justifyContent="start" alignItems="center" spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                        label="Other Activity"
                        value={customActivity}
                        onChange={(event) => setCustomActivity(event.target.value)}
                        sx={{ marginRight: 1 }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleAddCustomActivity} size="small">
                        <AddIcon />
                        </IconButton>
                    </Grid>
                    </Grid>
                </FormControl>
            
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Goal</FormLabel>
              <TextField
                    label="State your goal"
                    type="text"
                    fullWidth
                    margin="normal"
                    value={goal}
                    onChange={(event) => setGoal(event.target.value)}
                    inputProps={{
                        maxLength: 50,  // Replace with your desired maximum number of characters
                    }}
                />
            </FormControl>
            <TextField
              label="Traininghistory: sessions per week?"
              type="number"
              fullWidth
              margin="normal"
              value={trainingHistory}
              onChange={(event) => setTrainingHistory(event.target.value)}
            />
            <TextField
              label="For plan: sessions per week?"
              type="number"
              fullWidth
              margin="normal"
              value={sessionsPerWeek}
              onChange={(event) => setSessionsPerWeek(event.target.value)}
            />

            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">
                    Would you like som strengthtraining as well?
                </FormLabel>
                {[
                    'Strengthtraining with freeweights',
                    'Strengthtraining with machines',
                    'Strengthtraining with bodyweight exercises',
                ].map((activity) => (
                    <FormControlLabel
                    key={activity}
                    control={
                        <Checkbox
                        checked={alternativeActivities[alternativeActivityKeys[activity]]}
                        onChange={handleCheckboxChangeAlternativeActivity}
                        name={activity}
                        />
                    }
                    label={activity.replace(/([A-Z])/g, ' $1').trim()}
                    />
                ))}
                {customAlternativeActivities.map((activity) => (
                    <FormControlLabel
                    key={activity}
                    control={<Checkbox checked disabled />}
                    label={activity}
                    />
                ))}
                    <Grid container justifyContent="start" alignItems="center" spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                        label="Other Activity"
                        value={customAlternativeActivity}
                        onChange={(event) => setCustomAlternativeActivity(event.target.value)}
                        sx={{ marginRight: 1 }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleAddCustomAlternativeActivity} size="small">
                        <AddIcon />
                        </IconButton>
                    </Grid>
                    </Grid>
                </FormControl>
            
            <TextField
              label="Sessions per week for strengthtraining"
              type="number"
              fullWidth
              margin="normal"
              value={alternativeSessionsPerWeek}
              onChange={(event) => setAlternativeSessionsPerWeek(event.target.value)}
            />
            {loading && (
              <Box mt={2}>
                <LinearProgress />
                <Typography variant="subtitle1" align="center">
                  Creating your personalized plan... This may take a minute or two. Be patient.
                </Typography>
              </Box>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
              sx={{
                border: '1px solid #1976d2',
                '&:hover': {
                  backgroundColor: '#1976d2',
                  color: 'white',
                },
                mt: 2,
              }}
            >
              Submit
            </Button>
          </Box>
        </Paper>
        <Typography variant="body2" align="left" color="textSecondary" sx={{ mt: 2 }}>
          &copy; AP-Performance Solutions AS 2023
        </Typography>
      </Box>
    </Container>
  );
};

export default EnduranceQuestionnaire;
