import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import { CircularProgress, Box } from '@mui/material';
// import AdministratorDashboard from "./pages/AdministratorDashboard";
import Login from "./pages/Login";
import PaymentPage from "./pages/PaymentPage";
import Questionnaire from "./pages/Questionnaire";
import Registration from "./pages/Registration";
import UserDashboard from "./pages/UserDashboard";
import RunQuestionnaire from "./pages/RunQuestionnaire";
import StrengthQuestionnaire from "./pages/StrengthQuestionnaire";
import TriQuestionnaire from "./pages/TriQuestionnaire";
import DietQuestionnaire from "./pages/DietQuestionnaire";
import TrainingStats from "./pages/TrainingStats";
import EnduranceQuestionnaire from "./pages/EnduranceQuestionnaire";


import NavigationBar from "./components/NavigationBar";
import PaymentFailed from "./pages/PaymentFailed";
import ActivePlans from "./pages/ActivePlans";

interface DecodedToken {
  exp: number;
  // include other properties that you expect to receive from jwtDecode
}


const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // initialize isLoading as true

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds

      if (decodedToken.exp > currentTime) {
        setIsLoggedIn(true);
      } else {
        // Token is expired
        setIsLoggedIn(false);
        localStorage.removeItem('token');
      }
    }
    setIsLoading(false); // set isLoading to false after checking the token
  }, []);

  if (isLoading) { // if isLoading is true, don't render the Routes yet
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // fill the full viewport height
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Router>
      <NavigationBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <Routes>
        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/paymentFailed" element={<PaymentFailed />} />
        {/* <Route path="/user-dashboard" element={<UserDashboard />} /> */}
        {/* <Route path="/admin-dashboard" element={<AdministratorDashboard />} /> */}
        <Route path="/" element={isLoggedIn ? <UserDashboard /> : <Navigate to="/login" replace />} />
        <Route path="/payment" element={isLoggedIn ? <PaymentPage /> : <Navigate to="/login" replace />} />
        <Route path="/questionnaire" element={isLoggedIn ? <Questionnaire /> : <Navigate to="/login" replace />} />
        <Route path="/runQuestionnaire" element={isLoggedIn ? <RunQuestionnaire /> : <Navigate to="/" replace />} />
        <Route path="/strengthQuestionnaire" element={isLoggedIn ? <StrengthQuestionnaire /> : <Navigate to="/login" replace />} />
        <Route path="/triQuestionnaire" element={isLoggedIn ? <TriQuestionnaire /> : <Navigate to="/login" replace />} />
        <Route path="/dietQuestionnaire" element={isLoggedIn ? <DietQuestionnaire /> : <Navigate to="/login" replace />} />
        <Route path="/training-stats" element={isLoggedIn ? <TrainingStats /> : <Navigate to="/login" replace />} />
        <Route path="/activePlans" element={isLoggedIn ? <ActivePlans /> : <Navigate to="/login" replace />} />
        <Route path="/enduranceQuestionnaire" element={isLoggedIn ? <EnduranceQuestionnaire /> : <Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
};

export default App;