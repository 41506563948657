import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Button,
  Box,
  LinearProgress,
  Paper
} from '@mui/material';
import { createDietPlan, createDietQuestionnaire, createErrorLog, updateUser, getMonthlyPlanCounters, createPlanCounter, updatePlanCounter } from './../api';

const DietPlanQuestionnaire = () => {
  const [dietGoal, setDietGoal] = useState('');
  const [dietType, setDietType] = useState('');
  const [allergies, setAllergies] = useState('');
  const [currentWeight, setCurrentWeight] = useState('');
  const [targetWeight, setTargetWeight] = useState('');
  const [mealsPerDay, setMealsPerDay] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function getPreviousMonday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysSinceLastMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

    // If today is Monday, return today's date
    if (daysSinceLastMonday === 0) {
      return today;
    }

    const previousMonday = new Date(today);
    previousMonday.setDate(today.getDate() - daysSinceLastMonday);
    return previousMonday.toISOString().slice(0,10);
  }

  const previousMonday = getPreviousMonday();

  function getNextSunday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysUntilNextSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
  
    const nextSunday = new Date(today);
    nextSunday.setDate(today.getDate() + daysUntilNextSunday);
  
    return nextSunday.toISOString().slice(0,10); // returns date in YYYY-MM-DD format
  }
  
  const nextSunday = getNextSunday();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const dietQuestionnaireData = {
      userId: localStorage.getItem('userId'),
      dietGoal,
      dietType,
      allergies,
      currentWeight: parseFloat(currentWeight),
      targetWeight: parseFloat(targetWeight),
      mealsPerDay: parseInt(mealsPerDay),
    };

    const inputString: string = `
    IMPORTANT: Provide no extra text in your answer other than a valid JSON of the diet plan formatted like this:
    [
      {
        "date": "YYYY-MM-DD",
        "dailyCalories": "",
        "meals": [
          {
            "name": "MEALDESCRIPTION (be spesific in regards to amounts in ISO Units)",
            "calories": string;
            "proteinGrams": number; 
            "carbohydratesGram": number;
            "fatGrams": number;
          }
        ]
      }
    ]
    Context:
    Make a mealplan from ${previousMonday} to ${nextSunday} for a person with a diet goal of ${dietGoal} weight and a diet type of ${dietType}. 
    The person wants to have ${mealsPerDay} meals per day.
    The person has the following allergies or food restrictions: ${allergies || 'None'}
    The person's current weight is ${currentWeight} kg, and their target weight is ${targetWeight} kg.
    `;

    try {
      const monthlyPlanCounter = await getMonthlyPlanCounters();
  
      if (monthlyPlanCounter.length === 0) {
        await createPlanCounter(0, 1);
      } else if (monthlyPlanCounter.trainingPlanCount >= 4) {
        alert("You have created more than four plans this month. Please try again next month. In the meantime you can use the chat- and tips-function to request training advice");
        setLoading(false);
        return;
      } else {
        await updatePlanCounter(monthlyPlanCounter[0]._id, 
          monthlyPlanCounter[0].trainingPlanCount, 
          monthlyPlanCounter[0].dietPlanCount + 1, 
          monthlyPlanCounter[0].pressedNextWeekPlanningButtonCount);
      }
  
      // Continue with the submit
      const response = await createDietPlan(inputString);
      if(response) {
        const dietQuestionnaireDataResponse = await createDietQuestionnaire(dietQuestionnaireData);
        if(dietQuestionnaireDataResponse) {
          console.log("Created dietQ, updating user");
          try {
            console.log(dietQuestionnaireDataResponse._id);
            await updateUser(localStorage.getItem('userId') || '', { currentDietPlanQuestionnaire: dietQuestionnaireDataResponse._id })
          } catch (error) {
            console.error('Error updating user:', error);
          }
        }
      }
      navigate('/');
    } catch (error: any) {
      createErrorLog({
        userId: localStorage.getItem('userId'),
        error: JSON.stringify(error),
        errorMessage: error.message,
        errorOrigin: "DietQuestionnaire",
      }).then(errorLog => {
        console.log('Error log created:', errorLog);
      }).catch(error => {
        console.error('Error creating error log:', error);
      });
      console.error('Error creating diet questionnaire:', error.message);
      setLoading(false);
      alert(`Something went wrong. Please try again.`);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Paper elevation={3} sx={{ padding: '2em', marginTop: '1em' }}>
          <Typography variant="h4" align="center" gutterBottom>
            Diet Plan Questionnaire
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Diet Goal</FormLabel>
              <RadioGroup
                value={dietGoal}
                onChange={(event) => setDietGoal(event.target.value)}
              >
                <FormControlLabel value="lose" control={<Radio />} label="Lose Weight" />
                <FormControlLabel value="maintain" control={<Radio />} label="Maintain Weight" />
                <FormControlLabel value="gain" control={<Radio />} label="Gain Weight" />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Diet Type</FormLabel>
              <RadioGroup
                value={dietType}
                onChange={(event) => setDietType(event.target.value)}
              >
                <FormControlLabel value="carnivore" control={<Radio />} label="Carnivore (animal-based)" />
                <FormControlLabel value="omnivore" control={<Radio />} label="Omnivore (animal & plan-based)" />
                <FormControlLabel value="vegetarian" control={<Radio />} label="Vegetarian" />
                <FormControlLabel value="vegan" control={<Radio />} label="Vegan" />
              </RadioGroup>
            </FormControl>
            <TextField
              label="Allergies or food restrictions"
              type="text"
              fullWidth
              margin="normal"
              value={allergies}
              onChange={(event) => setAllergies(event.target.value)}
            />
            <TextField
              label="Current Weight (kg)"
              type="number"
              fullWidth
              margin="normal"
              value={currentWeight}
              onChange={(event) => setCurrentWeight(event.target.value)}
            />
            <TextField
              label="Target Weight (kg)"
              type="number"
              fullWidth
              margin="normal"
              value={targetWeight}
              onChange={(event) => setTargetWeight(event.target.value)}
            />
            <TextField
              label="Desired meals per day"
              type="number"
              fullWidth
              margin="normal"
              value={mealsPerDay}
              onChange={(event) => setMealsPerDay(event.target.value)}
            />
            {loading && (
              <Box mt={2}>
                <LinearProgress />
                <Typography variant="subtitle1" align="center">
                  Creating your personalized plan... This may take a minute or two. Be patient.
                </Typography>
              </Box>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
              sx={{
                border: '1px solid #1976d2',
                '&:hover': {
                  backgroundColor: '#1976d2',
                  color: 'white',
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Paper>
        <Typography variant="body2" align="left" color="textSecondary" sx={{ mt: 2 }}>
          &copy; AP-Performance Solutions AS 2023
        </Typography>
      </Box>
    </Container>
  );
};

export default DietPlanQuestionnaire;
