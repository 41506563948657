import React from 'react';
import { Link } from 'react-router-dom';

const PaymentFailed = () => {
  return (
    <div>
      <h1>Payment Failed</h1>
      <p>
        There was an issue with your payment. Please{' '}
        <Link to="/register">register again</Link> and retry the payment process.
      </p>
    </div>
  );
};

export default PaymentFailed;
