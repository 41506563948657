import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";


interface NavigationBarProps {
  isLoggedIn: boolean;
  setIsLoggedIn: (loggedIn: boolean) => void;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ isLoggedIn, setIsLoggedIn }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    navigate('/login');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          AI Personal Trainer
        </Typography>
        <Box>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {isLoggedIn ? (
              <>
                <MenuItem component={Link} to="/" onClick={handleClose}>
                  Dashboard
                </MenuItem>
                {/* <MenuItem component={Link} to="/admin-dashboard" onClick={handleClose}>
                  Admin
                </MenuItem> */}
                <MenuItem component={Link} to="/payment" onClick={handleClose}>
                  Subscription
                </MenuItem>
                <MenuItem component={Link} to="/training-stats" onClick={handleClose}>
                  Statistics
                </MenuItem>
                <MenuItem component={Link} to="/activePlans" onClick={handleClose}>
                  Active plans
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </>
            ) : (
              <>
                <MenuItem component={Link} to="/login" onClick={handleClose}>
                  Login
                </MenuItem>
                <MenuItem component={Link} to="/registration" onClick={handleClose}>
                  Registration
                </MenuItem>
              </>
            )}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;