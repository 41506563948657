import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, Container, Card, CardContent, CardActions, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { loginUser } from '../api';

interface LoginProps {
  setIsLoggedIn: (isLoggedIn: boolean) => void;
}

interface DecodedToken {
  exp: number;
  // include other properties that you expect to receive from jwtDecode
}

const Login: React.FC<LoginProps> = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken:DecodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
  
      if (decodedToken.exp > currentTime) {
        setIsLoggedIn(true);

      } else {
        // Token is expired
        setIsLoggedIn(false);
        localStorage.removeItem('token');
      }
    }
  }, [setIsLoggedIn]);
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const success = await loginUser(email, password);
    setIsLoggedIn(true);
    
    if (!success) {
      alert('Login failed. Please check your credentials and try again.');
      setIsLoggedIn(false);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      navigate('/');
    }
    
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <Card>
          <form onSubmit={handleSubmit}>
            <CardContent>
              <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                sx={{ mb: 2 }}
              />
            </CardContent>
            <CardActions>
              <Button type="submit" variant="contained" color="primary">
                {isLoading ? <CircularProgress size={24} color="secondary" /> : 'Login'}
              </Button>
            </CardActions>
          </form>
        </Card>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Don't have an account?{' '}
          <Link to="/registration">
            Register
          </Link>
        </Typography>
      </Box>
      <Typography variant="body2" align="left" color="textSecondary">
          Copyright AP-Performance Solutions AS 2023
        </Typography>
    </Container>
  );
};

export default Login;