import React, { useEffect, useState } from 'react';
import { PieChart, Pie, XAxis, YAxis,Tooltip, LineChart, Line, ResponsiveContainer } from 'recharts';
import { getAllUserTrainingSessions } from './../api';
import { getWeek } from 'date-fns';
import { Container, Typography, Select, MenuItem, Box, useTheme, useMediaQuery } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

interface TrainingSession {
  userId: string;
  date: Date;
  activity: string;
  sessionTitle: string;
  sessionDescription: string;
  intensityRatingOfPerceivedExertion?: number;
  durationInMinutes?: number;
  distanceInKm?: number;
  intensityZones: {
    one?: number;
    two?: number;
    three?: number;
    four?: number;
    five?: number;
  };
  strengthTraining: [
    {
      exercise?: string;
      repetitions?: number;
      load?: number;
    }
  ];
  feelingDuring?: string;
  executed?: boolean;
}

const extractUniqueActivities = (sessions: TrainingSession[]): string[] => {
    const uniqueActivities: string[] = [];
    sessions.forEach((session) => {
      if (session.activity && !uniqueActivities.includes(session.activity)) {
        uniqueActivities.push(session.activity);
      }
    });
    return uniqueActivities;
  };

const TrainingStats: React.FC = () => {
  const [trainingSessions, setTrainingSessions] = useState<TrainingSession[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<string | null>(null);
  const [viewport, setViewport] = useState({ start: 1, end: getWeek(new Date()) });
  // const [selectedPeriod, setSelectedPeriod] = useState<'yearly' | 'monthly' | 'weekly'>('yearly');
  // const [selectedExercise, setSelectedExercise] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllUserTrainingSessions();
      setTrainingSessions(data);
  
      const currentWeek = getWeek(new Date());
      setViewport((prevViewport) => ({ ...prevViewport, end: currentWeek }));
    };
  
    fetchData();
  }, []);

  const generateAllWeeksInYear = (year: number) => {
    const start = new Date(year, 0, 1);
    const end = new Date(year, 11, 31);
    let weekList: any[] = [];
    for (let day = start; day <= end; day.setDate(day.getDate() + 1)) {
      let week = getWeek(new Date(day));
      let weekId = `${year}-W${week}`;
      if (!weekList.includes(weekId)) {
        weekList.push(weekId);
      }
    }
    return weekList;
  };

  const getYearRange = (sessions: TrainingSession[]) => {
    let minYear = new Date().getFullYear();
    let maxYear = minYear;
  
    sessions.forEach((session) => {
      const year = new Date(session.date).getFullYear();
      minYear = Math.min(minYear, year);
      maxYear = Math.max(maxYear, year);
    });
  
    return { minYear, maxYear };
  };

  const calculateCompletionRate = (sessions: TrainingSession[]) => {
    const executedSessions = sessions.filter((session) => session.executed).length;
    const completionRate = (executedSessions / sessions.length) * 100;

    return completionRate;
    };

  const prepareActivityDistributionData = (sessions: TrainingSession[]) => {
    const executedSessions = sessions.filter((session) => session.executed);
    const activityCount: { [activity: string]: number } = {};
  
    executedSessions.forEach((session) => {
      activityCount[session.activity] = (activityCount[session.activity] || 0) + 1;
    });
  
    return Object.keys(activityCount).map((activity) => ({ name: activity, value: activityCount[activity] }));
  };  
  
  type WeeksData = {
    [weekId: string]: {
      [year: number]: {
        trainingFrequency: number;
        distanceCovered: number;
        totalDuration: number;
      };
    };
  };

  const prepareLineChartData = (sessions: TrainingSession[]) => {
    const executedSessions = sessions.filter((session) => session.executed);
  
    const { minYear, maxYear } = getYearRange(executedSessions);
  
    const weeksData: WeeksData = {};
  
    for (let year = minYear; year <= maxYear; year++) {
      const allWeeksInYear = generateAllWeeksInYear(year);
      allWeeksInYear.forEach((weekId) => {
        if (!weeksData[weekId]) {
          weeksData[weekId] = {};
        }
        weeksData[weekId][year] = {
          trainingFrequency: 0,
          distanceCovered: 0,
          totalDuration: 0,
        };
      });
    }
  
    executedSessions.forEach((session) => {
      const date = new Date(session.date);
      const year = date.getFullYear();
      const week = getWeek(date);
      const weekId = `${year}-W${week}`;
  
      weeksData[weekId][year].trainingFrequency += 1;
      weeksData[weekId][year].distanceCovered += session.distanceInKm || 0;
      weeksData[weekId][year].totalDuration += session.durationInMinutes || 0;
    });
  
    // Adjust weeksData to become cumulative
    for (let year = minYear; year <= maxYear; year++) {
      let cumulativeTrainingFrequency = 0;
      let cumulativeDistanceCovered = 0;
      let cumulativeTotalDuration = 0;
  
      for (let weekIndex = 1; weekIndex <= 52; weekIndex++) {
        const weekId = `${year}-W${weekIndex}`;
  
        if (weeksData[weekId] && weeksData[weekId][year]) {
          cumulativeTrainingFrequency += weeksData[weekId][year].trainingFrequency;
          cumulativeDistanceCovered += weeksData[weekId][year].distanceCovered;
          cumulativeTotalDuration += weeksData[weekId][year].totalDuration;
  
          weeksData[weekId][year].trainingFrequency = cumulativeTrainingFrequency;
          weeksData[weekId][year].distanceCovered = cumulativeDistanceCovered;
          weeksData[weekId][year].totalDuration = cumulativeTotalDuration;
        }
      }
    }
  
    let lineChartData: any[] = [];
  
    for (let weekIndex = 1; weekIndex <= 52; weekIndex++) {
      const item: any = { weekId: `W${weekIndex}` };
  
      for (let year = minYear; year <= maxYear; year++) {
        const weekId = `${year}-W${weekIndex}`;
        const cumulativeData = weeksData[weekId] || {
          [year]: {
            trainingFrequency: 0,
            distanceCovered: 0,
            totalDuration: 0,
          },
        };
  
        item[`${year}-trainingFrequency`] = cumulativeData[year].trainingFrequency;
        item[`${year}-distanceCovered`] = cumulativeData[year].distanceCovered;
        item[`${year}-totalDuration`] = cumulativeData[year].totalDuration;
      }
  
      lineChartData.push(item);
    }

    lineChartData = lineChartData.slice(viewport.start - 1, viewport.end);
    console.log(lineChartData);
    return lineChartData;
  };
  


const handleActivityChange = (event: SelectChangeEvent) => {
  setSelectedActivity(event.target.value as string || null);
};
const filteredSessions = selectedActivity
? trainingSessions.filter((session) => session.activity === selectedActivity)
: trainingSessions;

const activityList = extractUniqueActivities(trainingSessions);

const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

const { minYear, maxYear } = getYearRange(trainingSessions);

    return (
      <Container maxWidth={false}>
        <Typography>Select activity:</Typography>
        <Select
          fullWidth
          value={selectedActivity || ''}
          onChange={handleActivityChange}
        >
          <MenuItem value="">All activities</MenuItem>
          {activityList.map((activity) => (
            <MenuItem key={activity} value={activity}>
              {activity}
            </MenuItem>
          ))}
        </Select>
        <Box width="100%" overflow="hidden">
        <ResponsiveContainer width="100%" height={300}>
        <LineChart data={prepareLineChartData(filteredSessions)}>
          <XAxis 
              dataKey="weekId" 
              stroke="#8884d8" // this changes the color of the axis line
              tick={{ fill: '#8884d8' }} // this changes the color of the tick (weekId)
            >
            </XAxis>
          <YAxis yAxisId="left" hide />
          <Tooltip />
          {/* <Legend /> */}
          {[...Array(maxYear - minYear + 1)].map((_, i) => {
            const year = minYear + i;
            return (
              <Line 
                key={year}
                yAxisId="left"
                type="monotone"
                dataKey={`${year}-trainingFrequency`}
                stroke="#8884d8"
                dot={false}
                />
            )
          })}
        </LineChart>
        </ResponsiveContainer>
        <Typography variant="h5" component="div" gutterBottom align="center">
          Cumulative trainingsessions
        </Typography>
        </Box>
        <Box width="100%" overflow="hidden">
          <ResponsiveContainer width="100%" height={300}>
          <LineChart data={prepareLineChartData(filteredSessions)}>
            <XAxis 
              dataKey="weekId" 
              stroke="#8884d8" // this changes the color of the axis line
              tick={{ fill: '#8884d8' }} // this changes the color of the tick (weekId)
            >
            </XAxis>
            <YAxis yAxisId="left" hide />
            <Tooltip />
            {/* <Legend /> */}
            {[...Array(maxYear - minYear + 1)].map((_, i) => {
              const year = minYear + i;
              return (
                <Line 
                  key={year}
                  yAxisId="left"
                  type="monotone"
                  dataKey={`${year}-distanceCovered`}
                  stroke="#8884d8"
                  dot={false} 
                  />
              )
            })}
          </LineChart>
          </ResponsiveContainer>
          <Typography variant="h5" component="div" gutterBottom align="center">
          Cumulative trainingdistance
        </Typography>
        </Box>
        <Box width="100%" overflow="hidden">
          
          <ResponsiveContainer width="100%" height={300}>
          <LineChart data={prepareLineChartData(filteredSessions)}>
          <XAxis 
            dataKey="weekId" 
            stroke="#8884d8" // this changes the color of the axis line
            tick={{ fill: '#8884d8' }} // this changes the color of the tick (weekId)
          >
          </XAxis>
            <YAxis yAxisId="left" hide />
            <Tooltip />
            {/* <Legend /> */}
            {[...Array(maxYear - minYear + 1)].map((_, i) => {
              const year = minYear + i;
              return (
                <Line 
                  key={year}
                  yAxisId="left"
                  type="monotone"
                  dataKey={`${year}-totalDuration`}
                  stroke="#8884d8"
                  dot={false} 
                  />
              )
            })}
          </LineChart>
          </ResponsiveContainer>
          <Typography variant="h5" component="div" gutterBottom align="center">
          Cumulative trainingduration
          </Typography>
        </Box>
        <Typography variant="h5" component="div" gutterBottom align="center" sx={{ mt: 3 }}>
          Activity Distribution
        </Typography>
        <Box display="flex" justifyContent="center">
          <PieChart
            width={isMobile ? window.innerWidth - 20 : 400}
            height={isMobile ? window.innerWidth - 20 : 400}
          >
            <Pie
              data={prepareActivityDistributionData(trainingSessions)}
              cx={isMobile ? (window.innerWidth - 20) / 2 : 200}
              cy={isMobile ? (window.innerWidth - 20) / 2 : 200}
              outerRadius={isMobile ? (window.innerWidth - 20) / 4 : 100}
              fill="#8884d8"
              dataKey="value"
              nameKey="name"
              label
            />
            <Tooltip />
          </PieChart>
        </Box>
        <Typography variant="h5" component="div" gutterBottom align="center" sx={{ mt: 3 }}>
          Completion Rate
        </Typography>
        <Typography variant="h5" component="div" align="center" sx={{ mb: 3 }}>
          {calculateCompletionRate(trainingSessions).toFixed(2)}%
        </Typography>
        <Typography variant="body2" align="center" color="text.secondary" sx={{ mt: 5 }}>
          Copyright AP-Performance Solutions AS 2023
        </Typography>
      </Container>
  );
}

export default TrainingStats;

   // const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];


  // const prepareIntensityDistributionData = (sessions: TrainingSession[]) => {
  //   const executedSessions = sessions.filter((session) => session.executed);
  //   const intensityCounts: { [zone: string]: number } = {};
  
  //   executedSessions.forEach((session) => {
  //     if (session.intensityZones) {
  //       Object.entries(session.intensityZones).forEach(([zone, count]) => {
  //         intensityCounts[zone] = (intensityCounts[zone] || 0) + (count || 0);
  //       });
  //     }
  //   });
  
  //   return Object.keys(intensityCounts).map((zone, index) => ({
  //     zone,
  //     count: intensityCounts[zone],
  //     color: COLORS[index % COLORS.length],
  //   }));
  // };

    // const prepareFeelingDuringTrainingData = (sessions: TrainingSession[]) => {
  //   const executedSessions = sessions.filter((session) => session.executed);
  //   const feelingCounts: { [feeling: string]: number } = {};
  
  //   executedSessions.forEach((session) => {
  //     const feeling = session.feelingDuring || '';
  //     feelingCounts[feeling] = (feelingCounts[feeling] || 0) + 1;
  //   });
  
  //   return Object.keys(feelingCounts).map((feeling) => ({ feeling, count: feelingCounts[feeling] }));
  // };

  // const prepareStrengthTrainingBarChartData = (
    //     sessions: TrainingSession[],
    //     period: 'yearly' | 'monthly' | 'weekly',
    //     selectedExercise?: string
    //   ) => {
    //     const executedSessions = sessions.filter((session) => session.executed);
    //     const periodData: { [key: string]: number } = {};
      
    //     executedSessions.forEach((session) => {
    //       const sessionDate = new Date(session.date);
    //       let periodKey: string;
    //       if (period === 'yearly') {
    //         periodKey = `${sessionDate.getFullYear()}`;
    //       } else if (period === 'monthly') {
    //         periodKey = `${sessionDate.getFullYear()}-${sessionDate.getMonth() + 1}`;
    //       } else {
    //         periodKey = `${sessionDate.getFullYear()}-W${Math.floor(sessionDate.getDate() / 7) + 1}`;
    //       }
      
    //       session.strengthTraining.forEach((exercise) => {
    //         if (!selectedExercise || exercise === selectedExercise) {
    //           periodData[periodKey] = (periodData[periodKey] || 0) + (exercise.load || 0) * (exercise.repetitions || 0);
    //         }
    //       });
    //     });
      
    //     return Object.entries(periodData).map(([key, liftedLoad]) => ({
    //       period: key,
    //       liftedLoad,
    //     }));
    //   };

    
    
    // const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // setSelectedPeriod(event.target.value as 'yearly' | 'monthly' | 'weekly');
    // };
    
    // const handleExerciseChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // setSelectedExercise(event.target.value || undefined);
    // };