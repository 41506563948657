import React, { useState } from 'react';
import {
  TextField,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Container,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
} from '@mui/material';
import { registerUser, createStripeCheckoutSession, deleteUser } from '../api';
import ReCAPTCHA from 'react-google-recaptcha';
import { initializeApp } from 'firebase/app';

const Registration = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstname] = useState('');
  const [lastName, setLastname] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('basic');
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(null);
  const [stripePubKey, setStripePubKey] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [agreed, setAgreed] = useState<boolean>(false);

  const firebaseConfig = {
    // Your firebase config object
  };
  initializeApp(firebaseConfig);

  const fetchEnvVariables = async () => {
    try {
      const responseStripe = await fetch('https://us-central1-ai-personal-trainer-386016.cloudfunctions.net/getStripePublishableKey');
      const dataStripe = await responseStripe.json();
      const { stripePublishableKey } = dataStripe;
      setStripePubKey(stripePublishableKey);
    } catch (error) {
      console.error('Error fetching OpenAI config:', error);
    }
  };

  fetchEnvVariables();

  const handleRecaptchaChange = (value: string | null) => {
    setRecaptchaResponse(value);
  };

  const handleAgreementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.target.checked);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const newUser = {
        email,
        password,
        firstName,
        lastName,
        role: 'user',
        recaptchaResponse,
      };
      const registerResponse = await registerUser(newUser);

      if (registerResponse.message === 'User registered successfully') {
        const userId = registerResponse.user._id;

        try {
          const session = await createStripeCheckoutSession(email, firstName, lastName, subscriptionType);
          const stripe = await (window as any).Stripe(stripePubKey);
          await stripe.redirectToCheckout({ sessionId: session.id });
        } catch (error) {
          console.error('Error creating Stripe checkout session:', error);
          deleteUser(userId).then(() => {
            console.log('User deleted due to error:', userId);
          });
        }
      } else {
        console.error('Error registering user:', registerResponse.message);
      }
    } catch (error: any) {
      console.error('Error registering user:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Registration
        </Typography>
        <Paper elevation={3} style={{ padding: '2em', marginTop: '1em' }}>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Firstname"
              value={firstName}
              onChange={(e) => setFirstname(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Lastname"
              value={lastName}
              onChange={(e) => setLastname(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="legend">Subscription Type</FormLabel>
              <RadioGroup value={subscriptionType} onChange={(e) => setSubscriptionType(e.target.value)}>
                <FormControlLabel value="basic" control={<Radio />} label="AI Personal Trainer - NOK 199,- per month (Around 19USD/months)" />
              </RadioGroup>
            </FormControl>
            <Box mt={2}>
              <ReCAPTCHA sitekey="6LeJgPUlAAAAAFkU7JAp8kbMR-m3HwT57X8HX2rB" onChange={handleRecaptchaChange} />
            </Box>
            <Box mt={2}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isLoading}
                onClick={handleDialogOpen}
              >
                {isLoading ? <CircularProgress size={24} color="secondary" /> : 'Register'}
              </Button>
            </Box>
          </form>
        </Paper>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <a href="https://aipt.no/wp-content/uploads/2023/05/Privacy-policy.pdf" target="_blank" rel="noopener noreferrer">
            Privacy policy
          </a>
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          <a href="https://aipt.no/wp-content/uploads/2023/05/Sales-policy.pdf" target="_blank" rel="noopener noreferrer">
            Sales policy
          </a>
        </Typography>
      </Box>
      <Typography variant="body2" align="left" color="textSecondary">
        Copyright AP-Performance Solutions AS 2023
      </Typography>

      {/* Dialog Box */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Acknowledgement and Consent

            This application incorporates an advanced AI-powered Personal Trainer and Coach BOT designed to assist with your fitness and health objectives. However, the precision, applicability, and impact of the advice and suggestions provided by the AI BOT may fluctuate based on various factors. It's crucial to understand that the recommendations offered by the AI BOT are intended to supplement, not replace, professional medical or fitness advice.

            By ticking the box below, you explicitly confirm your understanding and agreement to the following terms:

            The AI BOT provides suggestions that may not always align perfectly with your unique health or fitness situation.
            While the AI BOT is designed using advanced technology, it does not have the capacity to fully comprehend or interpret your personal medical history or specific fitness needs.
            You accept sole responsibility for your health and well-being and will exercise prudent judgement when implementing advice or recommendations provided by the AI BOT.
            You commit to seeking professional medical or fitness advice before making any substantial changes to your existing health or fitness routines.
            We strive to provide you with supportive tools to enhance your health journey, but your safety and well-being remain your responsibility. By checking this box, you acknowledge that you have understood, accepted, and agreed to these terms.
          </Typography>
          <FormControlLabel
            control={<Checkbox checked={agreed} onChange={handleAgreementChange} color="primary" />}
            label="I agree"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!agreed}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Registration;