import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Container,
  Box,
  Paper,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getUserById, updateUser } from '../api';

const ActivePlans = () => {
  const [currentRunQuestionnaire, setCurrentRunQuestionnaire] = useState<string | null>(null);
  const [currentStrengthQuestionnaire, setCurrentStrengthQuestionnaire] = useState<string | null>(null);
  const [currentDietPlanQuestionnaire, setCurrentDietPlanQuestionnaire] = useState<string | null>(null);

  const isMounted = useRef(true);

  useEffect(() => {
    fetchData();

    return () => {
      // When the component unmounts set isMounted to false
      isMounted.current = false;
    }
  }, []);

  const fetchData = async () => {
    const userId = localStorage.getItem('userId');
    if (userId !== null) {
      const user = await getUserById(userId);

      // Only update state if the component is still mounted
      if (isMounted.current) {
        setCurrentRunQuestionnaire(user.currentRunQuestionnaire ? user.currentRunQuestionnaire.toString() : null);
        setCurrentStrengthQuestionnaire(user.currentStrengthQuestionnaire ? user.currentStrengthQuestionnaire.toString() : null);
        setCurrentDietPlanQuestionnaire(user.currentDietPlanQuestionnaire ? user.currentDietPlanQuestionnaire.toString() : null);
      }
    }
  };

  const handleDeletePlan = async (planType: string) => {
    const userId = localStorage.getItem('userId');
    if (userId !== null) {
      if (planType === 'run') {
        await updateUser(userId, { currentRunQuestionnaire: null });
      } else if (planType === 'strength') {
        await updateUser(userId, { currentStrengthQuestionnaire: null });
      } else if (planType === 'diet') {
        await updateUser(userId, { currentDietPlanQuestionnaire: null });
      }
      // Optionally re-fetch the user data to keep state consistent:
      await fetchData();
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Active Plans
        </Typography>
        <Paper elevation={3} style={{ padding: '2em', marginTop: '1em' }}>
          <Typography variant="h6">
            Strength Plans
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {currentStrengthQuestionnaire ? 
              <> 
                You have an active strength plan. The ID of your current strength questionnaire: {currentStrengthQuestionnaire}
                <IconButton onClick={() => handleDeletePlan('strength')}><DeleteIcon /></IconButton> 
              </> 
            : 
              'You have no active strength plan.'
            }
          </Typography>
          <Box mt={2}>
            <Typography variant="h6">
              Run Plans
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {currentRunQuestionnaire ? 
                <> 
                  You have an active run plan. The ID of your current run questionnaire: {currentRunQuestionnaire}
                  <IconButton onClick={() => handleDeletePlan('run')}><DeleteIcon /></IconButton>
                </>
              : 
                'You have no active run plan.'
              }
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="h6">
              Diet Plans
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {currentDietPlanQuestionnaire ? 
                <>
                  You have an active diet plan. The ID of your current Diet Plan Questionnaire is: {currentDietPlanQuestionnaire}
                  <IconButton onClick={() => handleDeletePlan('diet')}><DeleteIcon /></IconButton>
                </>
              : 
                'You have no active diet plan.'
              }
            </Typography>
          </Box>
        </Paper>
      </Box>
      <Typography variant="body2" align="left" color="textSecondary">
        Copyright AP-Performance Solutions AS 2023
      </Typography>
    </Container>
  );
};

export default ActivePlans;
