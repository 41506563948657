import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  Paper
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { createRunQuestionnaire, updateUser, getMonthlyPlanCounters, createPlanCounter,updatePlanCounter } from '../api';
import { createRunPlan, createErrorLog } from './../api';
import AddIcon from '@mui/icons-material/Add';

type AlternativeActivitiesType = {
  strengthWithFreeweights: boolean;
  strengthWithMachines: boolean;
  strengthWithBodyweightExercises: boolean;
  cycling: boolean;
  swimming: boolean;
  hiking: boolean;
  skisOrRolleskis: boolean;
  assaultbike: boolean;
  [key: string]: boolean;
};

const RunQuestionnaire = () => {
  const [goalDistance, setGoalDistance] = useState('');
  const [averageKm, setAverageKm] = useState('');
  const [sessionsPerWeek, setSessionsPerWeek] = useState('');
  const [alternativeSessionsPerWeek, setAlternativeSessionsPerWeek] = useState('');
  const [loading, setLoading] = useState(false);

  const [alternativeActivities, setAlternativeActivities] = useState<AlternativeActivitiesType>({
    strengthWithFreeweights: false,
    strengthWithMachines: false,
    strengthWithBodyweightExercises: false,
    cycling: false,
    swimming: false,
    hiking: false,
    skisOrRolleskis: false,
    assaultbike: false,
  });

  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);

  const activityKeys: Record<string, keyof AlternativeActivitiesType> = {
    'Strength with freeweights': 'strengthWithFreeweights',
    'Strength with machines': 'strengthWithMachines',
    'Strength with bodyweight exercises': 'strengthWithBodyweightExercises',
    Cycling: 'cycling',
    Swimming: 'swimming',
    Hiking: 'hiking',
    'Skis/rolleskis': 'skisOrRolleskis',
    Assaultbike: 'assaultbike',
  };

  const [customActivity, setCustomActivity] = useState('');
  const [customActivities, setCustomActivities] = useState<string[]>([]);


  const navigate = useNavigate();

  function getPreviousMonday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysSinceLastMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

    // If today is Monday, return today's date
    if (daysSinceLastMonday === 0) {
      return today;
    }

    const previousMonday = new Date(today);
    previousMonday.setDate(today.getDate() - daysSinceLastMonday);
    return previousMonday.toISOString().slice(0,10);
  }

  const previousMonday = getPreviousMonday();

  function getNextSunday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysUntilNextSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
  
    const nextSunday = new Date(today);
    nextSunday.setDate(today.getDate() + daysUntilNextSunday);
  
    return nextSunday.toISOString().slice(0,10); // returns date in YYYY-MM-DD format
  }
  
  const nextSunday = getNextSunday();
  

  function getWeekNumber(date: Date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const activityName = event.target.name;
    const isChecked = event.target.checked;
    setAlternativeActivities({
      ...alternativeActivities,
      [activityName]: isChecked,
    });
    setSelectedActivities((prevSelectedActivities) => {
      if (isChecked) {
        return [...prevSelectedActivities, activityName];
      } else {
        return prevSelectedActivities.filter((name) => name !== activityName);
      }
    });
  };

  const handleAddCustomActivity = () => {
    if (customActivity.trim()) {
      setCustomActivities([...customActivities, customActivity.trim()]);
      setCustomActivity('');
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    const runQuestionnaireData = {
      userId: localStorage.getItem('userId'),
      goalDistance: parseInt(goalDistance),
      averageKmPerWeekLastThreeMonths: parseInt(averageKm),
      sessionPerWeekForPlan: parseInt(sessionsPerWeek),
      weekStartPlan: getWeekNumber(new Date()),
      alternativeActivities: selectedActivities,
      alternativeSessionsPerWeek: parseInt(alternativeSessionsPerWeek),
    };

    const theseSelectedActivities = Object.keys(alternativeActivities).filter(
      (activity) => alternativeActivities[activity]
    );

    const inputString: string =
    `IMPORTANT: Provide no extra text in your answer other than the JSON of the trainingSessions formatted like this:
    [{
      "date": "YYYY-MM-DD",
      "activity": "String",
      "sessionTitle": "String",
      "sessionDescription": "String",
    }]
    Context:
    Make a run-plan from ${previousMonday} until ${nextSunday} for a person with a goal of running a ${goalDistance}k race. 
    The last three months run distance average per week: ${averageKm}.
    Take that into account when planning. 
    The person wants to train ${sessionsPerWeek} run sessions per week.
    The person also wants some alternative sessions with these activities ${alternativeSessionsPerWeek} times per week:
    ${theseSelectedActivities.length ? `Alternative activities: ${theseSelectedActivities.join(', ')}` : ''}
    Be specific in your description of the sessions.
    Rest days should NOT be included in the plan
    `;

    try {
      const monthlyPlanCounter = await getMonthlyPlanCounters();
  
      if (monthlyPlanCounter.length === 0) {
        await createPlanCounter(1, 0);
      } else if (monthlyPlanCounter.trainingPlanCount >= 4) {
        alert("You have created more than four plans this month. Please try again next month. In the meantime you can use the chat- and tips-function to request training advice");
        setLoading(false);
        return;
      } else {
        await updatePlanCounter(monthlyPlanCounter[0]._id, 
          monthlyPlanCounter[0].trainingPlanCount + 1, 
          monthlyPlanCounter[0].dietPlanCount, 
          monthlyPlanCounter[0].pressedNextWeekPlanningButtonCount);
      }
    
      // Continue with the submit
      const response = await createRunPlan(inputString);
      if(response) {
        const runQuestionnaireDataResponse = await createRunQuestionnaire(runQuestionnaireData);
        if(runQuestionnaireDataResponse) {
          try {
            console.log(runQuestionnaireDataResponse._id);
            await updateUser(localStorage.getItem('userId') || '', { currentRunQuestionnaire: runQuestionnaireDataResponse._id } )
          } catch (error) {
            console.error('Error updating user:', error);
          }
        }
      }
      navigate('/');
      
    } catch (error: any) {
      createErrorLog({
        userId: localStorage.getItem('userId'),
        error: JSON.stringify(error),
        errorMessage: error.message,
        errorOrigin: "RunQuestionnaire",
      }).then(errorLog => {
          console.log('Error log created:', errorLog);
      }).catch(error => {
          console.error('Error creating error log:', error);
      });
      console.error('Error creating run questionnaire:', error.message);
      setLoading(false);
      alert(`Something went wrong. Please try again.`);
    }
  };


  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Paper elevation={3} sx={{ padding: '2em', marginTop: '1em' }}>
          <Typography variant="h4" align="center" gutterBottom>
            Run Questionnaire
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Goal Distance</FormLabel>
              <RadioGroup
                value={goalDistance}
                onChange={(event) => setGoalDistance(event.target.value)}
              >
                <FormControlLabel value="3" control={<Radio />} label="3k" />
                <FormControlLabel value="5" control={<Radio />} label="5k" />
                <FormControlLabel value="10" control={<Radio />} label="10k" />
                <FormControlLabel value="21" control={<Radio />} label="Half Marathon" />
                <FormControlLabel value="42" control={<Radio />} label="Marathon" />
              </RadioGroup>
            </FormControl>
            <TextField
              label="Average kilometers per week (last 3 months)"
              type="number"
              fullWidth
              margin="normal"
              value={averageKm}
              onChange={(event) => setAverageKm(event.target.value)}
            />
            <TextField
              label="Run-sessions per week?"
              type="number"
              fullWidth
              margin="normal"
              value={sessionsPerWeek}
              onChange={(event) => setSessionsPerWeek(event.target.value)}
            />
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">
                Do you want some other activities as well? Check the boxes of the
                activity you would like and add the number of sessions per week at the bottom:
              </FormLabel>
              {[
                'Strengthtraining with freeweights',
                'Strengthtraining with machines',
                'Strengthtraining with bodyweight exercises',
                'Cycling',
                'Swimming',
                'Hiking',
                'Cross-country-skiing or rollerskiing',
                'Assaultbike',
              ].map((activity) => (
                <FormControlLabel
                  key={activity}
                  control={
                    <Checkbox
                      checked={alternativeActivities[activityKeys[activity]]}
                      onChange={handleCheckboxChange}
                      name={activity}
                    />
                  }
                  label={activity.replace(/([A-Z])/g, ' $1').trim()}
                />
              ))}
              {customActivities.map((activity) => (
                <FormControlLabel
                  key={activity}
                  control={<Checkbox checked disabled />}
                  label={activity}
                />
              ))}
                <Grid container justifyContent="start" alignItems="center" spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      label="Other Activity"
                      value={customActivity}
                      onChange={(event) => setCustomActivity(event.target.value)}
                      sx={{ marginRight: 1 }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={handleAddCustomActivity} size="small">
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
            </FormControl>
            <TextField
              label="Sessions per week for other activities"
              type="number"
              fullWidth
              margin="normal"
              value={alternativeSessionsPerWeek}
              onChange={(event) => setAlternativeSessionsPerWeek(event.target.value)}
            />
            {loading && (
              <Box mt={2}>
                <LinearProgress />
                <Typography variant="subtitle1" align="center">
                  Creating your personalized plan... This may take a minute or two. Be patient.
                </Typography>
              </Box>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
              sx={{
                border: '1px solid #1976d2',
                '&:hover': {
                  backgroundColor: '#1976d2',
                  color: 'white',
                },
                mt: 2,
              }}
            >
              Submit
            </Button>
          </Box>
        </Paper>
        <Typography variant="body2" align="left" color="textSecondary" sx={{ mt: 2 }}>
          &copy; AP-Performance Solutions AS 2023
        </Typography>
      </Box>
    </Container>
  );
};

export default RunQuestionnaire;
