const API_URL = 'https://api-crnjnal3ya-ew.a.run.app';

//For later:

// if (!response.ok) {
//   throw new Error(`Error fetching activities: ${response.statusText}`);
// }

// Activity-related API calls
export const fetchActivities = async () => {
    const response = await fetch(`${API_URL}/api/activities`);
    const data = await response.json();
    return data;
  };
  
  export const fetchActivityById = async (id: string) => {
    const response = await fetch(`${API_URL}/api/activities/${id}`);
    const data = await response.json();
    return data;
  };
  
  export const createActivity = async (activityData: any) => {
    const response = await fetch(`${API_URL}/api/activities`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(activityData),
    });
    const data = await response.json();
    return data;
  };
  
  export const updateActivity = async (id: string, activityData: any) => {
    const response = await fetch(`${API_URL}/api/activities/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(activityData),
    });
    const data = await response.json();
    return data;
  };
  
  export const deleteActivity = async (id: string) => {
    const response = await fetch(`${API_URL}/api/activities/${id}`, {
      method: 'DELETE',
    });
    const data = await response.json();
    return data;
  };

  // Auth-related API calls
export const registerUser = async (userData: any) => {
    const response = await fetch(`${API_URL}/api/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });
    const data = await response.json();
    return data;
  };
  
  // export const loginUser = async (email: string, password: string) => {
  //   const response = await fetch(`${API_URL}/api/auth/login`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ email, password }),
  //   });
  //   const data = await response.json();
  //   return data;
  // };

  // ChatLog-related API calls
export const getChatLogs = async () => {
    const response = await fetch(`${API_URL}/api/chatlogs`, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };
  
  export const getChatLogById = async (id: string) => {
    const response = await fetch(`${API_URL}/api/chatlogs/${id}`, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };
  
  export const createChatLog = async (chatLogData: any) => {
    const response = await fetch(`${API_URL}/api/chatlogs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(chatLogData),
    });
    const data = await response.json();
    return data;
  };
  
  export const updateChatLog = async (id: string, chatLogData: any) => {
    const response = await fetch(`${API_URL}/api/chatlogs/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(chatLogData),
    });
    const data = await response.json();
    return data;
  };
  
  export const deleteChatLog = async (id: string) => {
    const response = await fetch(`${API_URL}/api/chatlogs/${id}`, {
      method: 'DELETE',
    });
    const data = await response.json();
    return data;
  };

  // DietPlan-related API calls
export const getDietPlansbyUserId = async () => {
  const token = localStorage.getItem('token');

    const response = await fetch(`${API_URL}/api/dietplans`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  };

  
  export const getDietPlanById = async (id: string) => {
    const response = await fetch(`${API_URL}/api/dietplans/${id}`, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };
  
  export const createDietPlan = async (inputString: any) => {
    const token = localStorage.getItem('token');

    const response = await fetch(`${API_URL}/api/dietplans`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({inputString}),
    });
    const data = await response.json();
    return data;
  };
  
  export const updateDietPlan = async (id: string, dietPlanData: any) => {
    const response = await fetch(`${API_URL}/api/dietplans/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dietPlanData),
    });
    const data = await response.json();
    return data;
  };
  
  export const deleteDietPlan = async (id: string) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/dietplans/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    return data;
  };

  // Exercise-related API calls
export const getExercises = async () => {
    const response = await fetch(`${API_URL}/api/exercises`, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };
  
  export const getExerciseById = async (id: string) => {
    const response = await fetch(`${API_URL}/api/exercises/${id}`, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };
  
  export const createExercise = async (exerciseData: any) => {
    const response = await fetch(`${API_URL}/api/exercises`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(exerciseData),
    });
    const data = await response.json();
    return data;
  };
  
  export const updateExercise = async (id: string, exerciseData: any) => {
    const response = await fetch(`${API_URL}/api/exercises/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(exerciseData),
    });
    const data = await response.json();
    return data;
  };
  
  export const deleteExercise = async (id: string) => {
    const response = await fetch(`${API_URL}/api/exercises/${id}`, {
      method: 'DELETE',
    });
    const data = await response.json();
    return data;
  };

  // Questionnaire-related API calls
export const getQuestionnaires = async () => {
    const response = await fetch(`${API_URL}/api/questionnaires`, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };

  export const getQuestionnaireByUserId = async (userId: string | null) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/questionnaires/${userId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  };
  
  export const getQuestionnaireById = async (id: string) => {
    const response = await fetch(`${API_URL}/api/questionnaires/${id}`, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };
  
  export const createQuestionnaire = async (questionnaireData: any) => {
    const token = localStorage.getItem('token');
  
    const response = await fetch(`${API_URL}/api/questionnaires`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(questionnaireData),
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message);
    }
  
    return data;
  };
  
  export const updateQuestionnaire = async (id: string, questionnaireData: any) => {
    const response = await fetch(`${API_URL}/api/questionnaires/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(questionnaireData),
    });
    const data = await response.json();
    return data;
  };
  
  export const deleteQuestionnaire = async (id: string) => {
    const response = await fetch(`${API_URL}/api/questionnaires/${id}`, {
      method: 'DELETE',
    });
    const data = await response.json();
    return data;
  };

  // TrainingSession-related API calls
export const createTrainingSession = async (trainingSessionData: any) => {
    const token = localStorage.getItem('token');

    const response = await fetch(`${API_URL}/api/trainingSessions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(trainingSessionData),
    });
    const data = await response.json();
    return data;
  };
  
  export const getAllTrainingSessions = async () => {
    const response = await fetch(`${API_URL}/api/training-sessions`, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };
  
  export const getTrainingSessionById = async (id: string) => {
    const response = await fetch(`${API_URL}/api/training-sessions/${id}`, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };

  export const getUserTrainingSessionsLastTenWeeks = async () => {
    const token = localStorage.getItem('token');

    const response = await fetch(`${API_URL}/api/trainingSessions/last-ten-weeks`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    return data;
  };

  export const getUserTrainingSessionsLastWeek = async () => {
    const token = localStorage.getItem('token');

    const response = await fetch(`${API_URL}/api/trainingSessions/last-week`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    return data;
  };

  export const getAllUserTrainingSessions = async () => {
    const token = localStorage.getItem('token');

    const response = await fetch(`${API_URL}/api/trainingSessions/getAllUser`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    return data;
  };
  
  export const updateTrainingSessionById = async (id: string, trainingSessionData: any) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/trainingSessions/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(trainingSessionData),
    });
    const data = await response.json();
    return data;
  };
  
  export const deleteTrainingSessionById = async (id: string) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/trainingSessions/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    return data;
  };

  // User-related API calls
export const createUser = async (userData: any) => {
    const response = await fetch(`${API_URL}/api/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });
    const data = await response.json();
    return data;
  };
  
  export const getUsers = async () => {
    const response = await fetch(`${API_URL}/api/users`, {
      method: 'GET',
    });
    const data = await response.json();
    return data;
  };
  
  export const getUserById = async (id:string) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/users/getUserById/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    const data = await response.json();
    return data;
  };
  
  export const updateUser = async (id: string, userData: any) => {
    console.log("Running update user func in frontend api file");
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/users/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    });
    const data = await response.json();
    return data;
  };
  
  export const deleteUser = async (id: string) => {
    const response = await fetch(`${API_URL}/api/users/${id}`, {
      method: 'DELETE',
    });
    const data = await response.json();
    return data;
  };

  //Run queationnaire
  export const createRunQuestionnaire = async (runQuestionnaireData: any) => {
    const token = localStorage.getItem('token');
  
    const response = await fetch(`${API_URL}/api/runQuestionnaires`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(runQuestionnaireData),
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message);
    }
  
    return data;
  };

  export const getRunQuestionnairesByUserId = async (userId: string | null) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/runQuestionnaires/${userId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  };

  export const getRunQuestionnairesById = async (questionnaireId: string | null) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/runQuestionnaires/${questionnaireId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  };

  //Strength questionnaire
  export const createStrengthQuestionnaire = async (strengthQuestionnaireData: any) => {
    const token = localStorage.getItem('token');
  
    const response = await fetch(`${API_URL}/api/strengthQuestionnaire`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(strengthQuestionnaireData),
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message);
    }
  
    return data;
  };

  export const getStrengthQuestionnairesByUserId = async (userId: string | null) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/strengthQuestionnaire/${userId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  };

  export const getStrengthQuestionnaireById = async (questionnaireId: string | null) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/strengthQuestionnaire/${questionnaireId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  };

  export const getDietPlanQuestionnaireById = async (questionnaireId: string | null) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/dietQuestionnaire/${questionnaireId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  };
  
  //RUNPLAN
  export const createRunPlan = async (inputString:any ) => {
    const token = localStorage.getItem('token');
  
    const response = await fetch(`${API_URL}/api/runPlan`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ inputString }),
      
    });
  
    const data = await response.json();
    console.log(data);
    if (!response.ok) {
      throw new Error(data.message);
    }
  
    return data;
  };
  
  export const getAllRunPlans = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/runPlans`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  };
  
  export const getRunPlanById = async (id: string) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/runPlans/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  };

  export const getLatestRunPlanByUserId = async () => {
    const token = localStorage.getItem('token');
  
    if (!token) {
      throw new Error('Token not found');
    }
  
    const response = await fetch(`${API_URL}/api/runPlan/latestByUser`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message);
    }
  
    return data;
  };

  export const getAllRunPlansByUserId = async () => {
    console.log("getting all plans");
    const token = localStorage.getItem('token');
  
    if (!token) {
      throw new Error('Token not found');
    }
  
    const response = await fetch(`${API_URL}/api/runPlan/getAllRunPlansByUserId`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
  
    const data = await response.json();
    console.log(data);
  
    if (!response.ok) {
      throw new Error(data.message);
    }
  
    return data;
  };
  
  export const updateRunPlanById = async (id: string, updatedRunPlanData: any) => {
    const token = localStorage.getItem('token');
  
    const response = await fetch(`${API_URL}/api/runPlans/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(updatedRunPlanData),
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message);
    }
  
    return data;
  };
  
  export const deleteRunPlanById = async (id: string) => {
    const token = localStorage.getItem('token');
  
    const response = await fetch(`${API_URL}/api/runPlans/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message);
    }
  
    return data;
  };

  //CHATGPT
  export async function fetchChatGPTFromBackend(inputString:any) {
    const token = localStorage.getItem('token');
    
    try {
      const response = await fetch(`${API_URL}/api/runplan/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ inputString }),
      });
  
      if (!response.ok) {
        throw new Error('Error fetching ChatGPT response from backend');
      }
  
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error:any) {
      console.error('Error fetching ChatGPT response from backend:', error.message);
      throw error;
    }
  };

  //STRIPE
  export const createStripeCheckoutSession = async (email: string, firstName: string, lastName: string, subscriptionType: string) => {
    try {
      const response = await fetch(`${API_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email, 
          firstName,
          lastName,
          subscriptionType,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create Stripe Checkout session');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  //DIETQUESTIONNAIRE
  export const createDietQuestionnaire = async (dietQuestionnaireData: any) => {
    const token = localStorage.getItem('token');
  
    const response = await fetch(`${API_URL}/api/dietQuestionnaire`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(dietQuestionnaireData),
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message);
    }
  
    return data;
  };

  export const loginUser = async (email: string, password: string) => {
    const response = await fetch(`${API_URL}/api/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('token', data.token);
      localStorage.setItem('userId', data.user._id);
      return true;
    } else {
      return false;
    }
  };

  export const createErrorLog = async (errorLogData: any) => {
    const token = localStorage.getItem('token');
    try {
        const response = await fetch(`${API_URL}/api/errorLogs`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(errorLogData)
        });

        if (!response.ok) {
            throw new Error('HTTP error ' + response.status);
        }

        const errorLog = await response.json();
        return errorLog;
    } catch (error) {
        console.error('Error creating error log:', error);
    }
};

export async function cancelSubscription(subscriptionId: string) {
  const response = await fetch(`${API_URL}/api/stripe/cancel-subscription`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ subscriptionId }),
  });

  if (!response.ok) {
    throw new Error("Failed to cancel subscription");
  }

  return response.json();
}

//get daily chatlogs for user
export const getDailyChatCounter = async () => {
  const token = localStorage.getItem('token');
  const date = new Date().toISOString().slice(0,10); // Get current date in 'yyyy-mm-dd' format

  try {
    const response = await fetch(`${API_URL}/api/chatCounters/${date}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const chatCounter = await response.json();
    console.log(chatCounter);
    return chatCounter;
  } catch (error) {
    console.error('Error getting chat counter:', error);
  }
};

export const createChatCounter = async () => {
  const token = localStorage.getItem('token');
  const date = new Date().toISOString().slice(0,10); // Get current date in 'yyyy-mm-dd' format

  try {
    const response = await fetch(`${API_URL}/api/chatCounters`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ date, count: 1 })
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const chatCounter = await response.json();
    return chatCounter;
  } catch (error) {
    console.error('Error creating chat counter:', error);
  }
};

export const updateChatCounter = async (chatCounter: any) => {
  const token = localStorage.getItem('token');
  const date = chatCounter.date; // Assuming date is stored in 'yyyy-mm-dd' format

  try {
    const response = await fetch(`${API_URL}/api/chatCounters/${date}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(chatCounter)
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const updatedChatCounter = await response.json();
    return updatedChatCounter;
  } catch (error) {
    console.error('Error updating chat counter:', error);
  }
};

//TRAININGTIPCOUNTER
export const getDailyTrainingTipCounter = async () => {
  const token = localStorage.getItem('token');
  const date = new Date().toISOString().slice(0,10); // Get current date in 'yyyy-mm-dd' format

  try {
    const response = await fetch(`${API_URL}/api/trainingTipCounter/${date}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const trainingTipCounter = await response.json();
    console.log(trainingTipCounter);
    return trainingTipCounter;
  } catch (error) {
    console.error('Error getting training tip counter:', error);
  }
};

export const createTrainingTipCounter = async () => {
  const token = localStorage.getItem('token');
  const date = new Date().toISOString().slice(0,10); // Get current date in 'yyyy-mm-dd' format

  try {
    const response = await fetch(`${API_URL}/api/trainingTipCounter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ date, count: 1 })
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const trainingTipCounter = await response.json();
    return trainingTipCounter;
  } catch (error) {
    console.error('Error creating training tip counter:', error);
  }
};

export const updateTrainingTipCounter = async (trainingTipCounter: any) => {
  const token = localStorage.getItem('token');
  const date = trainingTipCounter.date; // Assuming date is stored in 'yyyy-mm-dd' format

  try {
    const response = await fetch(`${API_URL}/api/trainingTipCounter/${date}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(trainingTipCounter)
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const updatedTrainingTipCounter = await response.json();
    return updatedTrainingTipCounter;
  } catch (error) {
    console.error('Error updating training tip counter:', error);
  }
};

//TRAININGPLANCOUNTER
export const getMonthlyPlanCounters = async () => {
  const token = localStorage.getItem('token');
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // JavaScript month is 0-11, so adding 1 to match the 1-12 format

  try {
    const response = await fetch(`${API_URL}/api/planCounter/month/${year}/${month}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const monthlyPlanCounters = await response.json();
    return monthlyPlanCounters;
  } catch (error) {
    console.error('Error getting monthly plan counters:', error);
  }
};

export const createPlanCounter = async (trainingPlanCount: number, dietPlanCount: number) => {
  const token = localStorage.getItem('token');
  const date = new Date().toISOString().slice(0,10); // Get current date in 'yyyy-mm-dd' format

  try {
    const response = await fetch(`${API_URL}/api/planCounter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ date, trainingPlanCount: trainingPlanCount, dietPlanCount: dietPlanCount })
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const planCounter = await response.json();
    return planCounter;
  } catch (error) {
    console.error('Error creating plan counter:', error);
  }
};

export const updatePlanCounter = async (planCounterId: string, trainingPlanCount: number, dietPlanCount: number, pressedNextWeekPlanningButtonCount: number ) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${API_URL}/api/planCounter/${planCounterId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ 
        trainingPlanCount, 
        dietPlanCount,
        pressedNextWeekPlanningButtonCount 
      })
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const updatedPlanCounter = await response.json();
    return updatedPlanCounter;
  } catch (error) {
    console.error('Error updating plan counter:', error);
  }
};

  //Run queationnaire
  export const createEnduranceQuestionnaire = async (runQuestionnaireData: any) => {
    const token = localStorage.getItem('token');
  
    const response = await fetch(`${API_URL}/api/enduranceQuestionnaires`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(runQuestionnaireData),
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message);
    }
  
    return data;
  };

  export const getEnduranceQuestionnairesByUserId = async (userId: string | null) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/enduranceQuestionnaires/${userId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  };

  export const getEnduranceQuestionnairesById = async (questionnaireId: string | null) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/api/enduranceQuestionnaires/${questionnaireId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  };
  